import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
    getInvestmentsThunk,
} from "./investmentThunk";


const initialState = {
    investments: [],
    total: 0,
    isLoading: false,
    limit: 10,
    page: 1,
};

const investmentSlice = createSlice({
    name: "investment",
    initialState,
    reducers: {
        changeLimit: (state, action) => {
            state.limit = action.payload;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInvestmentsThunk.fulfilled, (state, action) => {
                if (action?.payload?.status === 200) {
                    state.investments = action?.payload?.data?.body?.data;
                    state.total = action?.payload?.data?.body?.count;
                }
            })
    },
});

export const { changeLimit, changePage } = investmentSlice.actions;
export default investmentSlice.reducer;
