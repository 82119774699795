import React, { useState, useMemo, useEffect } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Accordion, Button, Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminNavBar from "../AdminNavBar";
import { getTutorialsThunk } from "../../../features/tutorial/tutorialThunk";
import { tutorialSelector } from "../../../features/tutorial/tutorialSelector";
import { changeTutorialLimit, changeTutorialPage } from "../../../features/tutorial/tutorialSlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import ReactPaginate from "react-paginate";
import { getFaqsThunk } from "../../../features/faq/faqThunk";
import { faqSelector } from "../../../features/faq/faqSelector";
import { changeLimit, changePage } from "../../../features/faq/faqSlice";
import { faCalendar, faComment, faUser } from "@fortawesome/free-regular-svg-icons";

// import { blogSelector } from "../../features/blog/blogSelector";
import moment from "moment";
import { getBlogsThunk } from "../../../features/blog/blogThunk";
import { blogSelector } from "../../../features/blog/blogSelector";


function LearningCenter() {
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const { tutorials, totalTutorials, tutorialLimit, tutorialPage } = useSelector(tutorialSelector);
  const { faqs, totalFaqs } = useSelector(faqSelector);
  const { token } = useSelector(authSelector); 
  const { blogs, total, limit, page } = useSelector(blogSelector);

  const [configTutorialObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      limit: tutorialLimit,
      page: tutorialPage,
    },
  });

  const [configObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      limit: limit,
      page: page,
    },
  });

  const pageCount = useMemo(() => {
    const pageCounter = Math.ceil(totalTutorials / tutorialLimit);
    return pageCounter;
  }, [totalTutorials, tutorialLimit]);

  const faqpageCount = useMemo(() => {
    const pageCounter = Math.ceil(totalFaqs / limit);
    return pageCounter;
  }, [totalFaqs, limit]);

  const toggleSidebar = () => setShowContent(!showContent);

  useEffect(() => {
    getTutorials();
    getFaqs();
  }, []);

  const getTutorials = async () => {
    await dispatch(getTutorialsThunk({ ...configTutorialObject }));
  };

  const handlePageClick = async (data) => {
    await dispatch(changeTutorialPage(data.selected + 1));
    await dispatch(
      getTutorialsThunk({
        ...configTutorialObject,
        params: {
          limit: tutorialLimit,
          page: data.selected + 1,
        },
      })
    );
  };

  const getFaqs = async () => {
    await dispatch(getFaqsThunk({ ...configTutorialObject }));
  };

  const handleFaqPageClick = async (data) => {
    await dispatch(changePage(data.selected + 1));
    await dispatch(
      getFaqsThunk({
        ...configObject,
        params: {
          limit: limit,
          page: data.selected + 1,
        },
      })
    );
  };

  const blogpageCount = useMemo(() => {
    const pageCounter = Math.ceil(total / limit);
    return pageCounter;
  }, [total, limit]);

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    await dispatch(getBlogsThunk({ ...configObject }));
  };

  const handlePageClickTwo = async (data) => {
    await dispatch(changePage(data.selected + 1));
    await dispatch(
      getBlogsThunk({
        ...configObject,
        params: {
          limit: limit,
          page: data.selected + 1,
        },
      })
    );
  };

  return (
    <>
      <div
        className={
          showContent
            ? "dashboard-main content-visible"
            : "dashboard-main"
        }
      >
        {/* <div>
          <AdminSidebar />
        </div> */}
        <div className="dashboard-body">
          <DashboardHeader toggleSidebar={toggleSidebar} />
          <Container>
            {/* <AdminNavBar /> */}
            <div className="top-navlinks">
            <Tab.Container id="left-tabs-example" defaultActiveKey="blog">
              <Nav variant="pills" className="navbar_list">
                <Nav.Item>
                  <Nav.Link eventKey="blog">Blog</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="faq">Help & FAQ</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="learning">Learning Center</Nav.Link>
                </Nav.Item>
              </Nav>

            <Tab.Content className="mt-5">
              <Tab.Pane eventKey="blog">
               <Row>
                  {
                    total > 0 ? blogs.map((item) => {
                      return (<Col lg={6} className="mb-3" key={item?._id}>
                        <div className="p-3 position-relative">
                          <div className="blog_img">
                            {
                              item?.image ? <img
                                src={`${process.env.REACT_APP_SERVER_URL}/uploads/blogs/${item?.image}`}
                                alt="blog"
                                className="w-100"
                              /> :
                                <img
                                  src={require("../../../images/faq.png")}
                                  alt="blog"
                                  className="w-100"
                                />
                            }

                          </div>
                          <div className="blog_content_wrapper p-4 mx-auto">
                            <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                              <li className="d-flex gap-2 align-items-center">
                                <div className="d-flex align-items-center justify-content-center admin_icon">
                                  <FontAwesomeIcon icon={faUser} />
                                </div>
                                <p className="mb-0">{item?.user?.full_name}</p>
                              </li>
                              <li className="d-flex gap-2 align-items-center">
                                <div className="d-flex align-items-center justify-content-center admin_icon">
                                  <FontAwesomeIcon icon={faCalendar} />
                                </div>
                                <p className="mb-0">{moment(item?.createdAt).format('DD MMMM, YYYY')}</p>
                              </li>
                              <li className="d-flex gap-2 align-items-center">
                                <div className="d-flex align-items-center justify-content-center admin_icon">
                                  <FontAwesomeIcon icon={faComment} />
                                </div>
                                <p className="mb-0">Comments({item?.comments?.length})</p>
                              </li>
                            </ul>
                            <h4 className="blog_heading pe-4">
                              {item?.title}
                            </h4>
                            <p className="mb-0 blog_description">
                              {
                                item?.description?.length > 150 ? item?.description.substring(0, 150) + '...' : item?.description
                              }
                            </p>
                            <Link to={`/blog-detail/${item?._id}`} className="blog_btn px-4 mt-4 btn btn-primary">Read More</Link>
                          </div>
                        </div>
                      </Col>)
                    })
                      : <>No Blog Found</>
                  }
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="faq">
                <div className="text-center py-4">
                  <span className="dashboard_badge success">FAQ</span>
                </div>
                <h2 className="fw-bold text-center mb-4">Have Any Question?</h2>
                <Row className="justify-content-center">
                  <Col lg={6} md={7}>
                    <Accordion className="faq_accordians">
                      {
                        totalFaqs > 0 && faqs.map((faq) => {
                          return (<Accordion.Item eventKey={faq?._id} key={faq?._id}>
                            <Accordion.Header>
                              {faq?.question}
                            </Accordion.Header>
                            <Accordion.Body className="text-muted">
                              {faq?.answer}
                            </Accordion.Body>
                          </Accordion.Item>)
                        })
                      }
                    </Accordion>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="learning">
                <div className="dashboard-content p-2 p-md-4 landing-center">
                  <h4 className="fw-bold text-center my-3">
                    {" "}
                    How Can we help you ?{" "}
                  </h4>
                  <Row className="justify-content-center">
                    <Col md={8} className="mb-4">
                      <Form className="masterSearch position-relative mb-5">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="search"
                            placeholder="name@example.com"
                            className="pe-5 border-0"
                          />
                        </Form.Group>
                        <Button className="rounded-circle align-items-center justify-content-center">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </Button>
                      </Form>
                      <div className="text-center">
                        <h5 className="fw-bold">How to Start Investing</h5>
                        <p className="text-muted">
                          Select an option to learn about Crowdfina Investment
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {
                      totalTutorials > 0 && tutorials.map((item) => {
                        return (<Col lg={3} md={6} className="mb-4" key={item?._id}>
                          <div className="investPropertycard p-3" >
                            <video className="property-img img-fluid mb-4" src={`${process.env.REACT_APP_SERVER_URL}/uploads/tutorials/${item?.video}`} controls />
                            {/* <img
                              className="property-img img-fluid mb-4"
                              src={require("../../../images/video-cta-img.png")}
                              alt=""
                            /> */}
                            <h4 className="propertyHeading">{item?.title}</h4>
                            <p className="expected_rate text-muted mb-0">
                              {" "}
                              By {item?.user?.full_name}
                            </p>
                          </div>
                        </Col>)
                      })
                    }
                  </Row>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LearningCenter;
