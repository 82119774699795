import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { authSelector } from "../../features/auth/authSelector";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';

export default function ProtectedRoutes({ Component }) {
    const navigate = useNavigate()
    const location = useLocation();
    const { token, userData } = useSelector(authSelector);


    useEffect(() => {
        if (!token || token === "undefined" || token === null) {
            navigate('/login')
        } else {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            var jwt = JSON.parse(jsonPayload);

            if (moment().unix() > jwt.exp) {
                navigate('/login')
            }
        }
    }, [location])

    return (
        <>
            <Component />
        </>
    )
}

