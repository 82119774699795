import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;

export const getInvestmentsThunk = createAsyncThunk(
    'investments/get',
    async ({ headers, params }) => {
        const config = {
            headers: { ...headers },
            params: params
        }
        try {
            const data = await axios.get(`${api_url}/investments/list`, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const getPortfolioStatsThunk = createAsyncThunk(
    'investments/portfolioStats',
    async ({ headers, params }) => {
        const config = {
            headers: { ...headers },
            params: params
        }
        try {
            const data = await axios.get(`${api_url}/investments/portfolioStats`, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const addInvestmentThunk = createAsyncThunk(
    'investments/add',
    async ({ body, headers }) => {
        const config = {
            headers: { ...headers }
        }
        try {
            const data = await axios.post(`${api_url}/investments/create`, body, config)
            return data

        } catch (error) {
            return error

        }
    }
)