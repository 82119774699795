import { faEnvelopeOpen, faImage } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from "react-bootstrap";
import MultiStep from 'react-multistep';
import { Link } from "react-router-dom";
import StepOne from './StepOne';
import StepTwo from './StepTwo';



function InvestorProfile() {
    const [activeStep, setActiveStep] = useState(1)
    //const nextButton = (onClick) => <button onClick={onClick}>Next</button>;
    return (
        <>
            <div className="authWrapper">
                <Row className="m-0">
                    <Col lg={6} md={6} className="ps-md-0">
                        <img src={require('../../images/authbanner.png')} className="login-side-img h-100" />
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="investor-profile-wrapper p-4 px-5 m-5">
                            <Link className="back_investor_profile text-decoration-none">
                                <span className='me-2'>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                                Investor Profile
                            </Link>

                            {/* <MultiStep activeStep={activeStep}> */}
                            {
                                activeStep === 1
                                    ? <StepOne title='Step 1' onNextStep={() => setActiveStep(2)} />
                                    : <StepTwo title='Step 2' />
                            }


                            {/* </MultiStep> */}

                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default InvestorProfile