import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
    getFaqsThunk,
} from "./faqThunk";


const initialState = {
    faqs: [],
    totalFaqs: 0,
    isLoading: false,
    limit: 8,
    page: 1,
};

const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {
        changeLimit: (state, action) => {
            state.limit = action.payload;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFaqsThunk.fulfilled, (state, action) => {
                if (action?.payload?.status === 200) {
                    state.faqs = action?.payload?.data?.body?.data;
                    state.totalFaqs = action?.payload?.data?.body?.count;
                }
            })
    },
});

export const { changeLimit, changePage } = faqSlice.actions;
export default faqSlice.reducer;
