import React, { useState, useEffect } from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import { Breadcrumb, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faComment, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from "react-router-dom";
import { getBlogDetailThunk, addBlogCommentThunk } from "../../features/blog/blogThunk"
import { blogSelector } from "../../features/blog/blogSelector";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../features/auth/authSelector";
import moment from 'moment';
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import { addCommentSchema } from "../../utils/validationsSchema";

function BLogDetail() {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        formik.setFieldValue("content", "");

    };
    const { token, userData } = useSelector(authSelector);
    const handleShow = () => {
        if (token) {
            setShow(true);
        } else {
            toast.error('User needs to be login to add comment.');
        }
    };
    const params = useParams();
    const { blog, blogs } = useSelector(blogSelector);
    const [configObject, setConfigObject] = useState({
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    useEffect(() => {
        getBlogDetail();
    }, [params]);

    const getBlogDetail = async () => {
        await dispatch(getBlogDetailThunk(params.id));
    };

    const formik = useFormik({
        initialValues: {
            blogId: params.id,
            content: ""
        },
        onSubmit: async (values, { resetForm }) => {
            const resp = await dispatch(addBlogCommentThunk({ ...configObject, body: values }));
            if (resp?.payload?.status === 200) {
                toast.success('comment added successfully');
                resetForm();
                setShow(false);
                getBlogDetail();
            } else {
                toast.error(resp?.payload?.response?.data?.message);
            }
        },
        validationSchema: addCommentSchema,
    });

    return (
        <>
            <Header />
            {/* ====== common banner ======= */}
            <div className="static_banner position-relative">
                <Container>
                    <div className="text-center">
                        <h3>Blog Detail</h3>
                    </div>
                    <Breadcrumb className="pages-breadcrumbs">
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Blog Detail</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
                <img src={require("../../images/banner-wave.png")} alt="wave" className="static-banner-shape" />
            </div>


            <div className="common-padding">
                <Container>
                    <Row>
                        <Col lg={12} className="mb-3">
                            <div className="p-3 position-relative">
                                <div className="blog_img rounded-4 blog_detail_img">
                                    {
                                        blog?.image ?
                                            <img
                                                src={`${process.env.REACT_APP_SERVER_URL}/uploads/blogs/${blog?.image}`}
                                                alt="blog"
                                                className="w-100 rounded-4 h-100 object-fit-cover"
                                            />
                                            :
                                            <img
                                                src={require("../../images/faq.png")}
                                                alt="blog"
                                                className="w-100 rounded-4 h-100 object-fit-cover"
                                            />
                                    }
                                </div>
                                <div className="blog_content_wrapper py-4 mx-auto mt-2 mw-100 shadow-none bg-none">
                                    <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faUser} />
                                            </div>
                                            <p className="mb-0">{blog?.user?.full_name}</p>
                                        </li>
                                        <li className="d-flex gap-2 align-items-center">
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </div>
                                            <p className="mb-0">{moment(blog?.createdAt).format('DD MMMM, YYYY')}</p>
                                        </li>
                                        <li className="d-flex gap-2 align-items-center comment_hover position-relative" onClick={handleShow}>
                                            <div className="d-flex align-items-center justify-content-center admin_icon">
                                                <FontAwesomeIcon icon={faComment} />
                                            </div>
                                            <p className="mb-0">Comments ({blog?.comments?.length})</p>
                                            {/* <span className='comment_counts position-absolute'>7</span> */}
                                        </li>
                                    </ul>

                                    <Modal show={show} onHide={handleClose} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Comment</Modal.Title>
                                        </Modal.Header>
                                        <Form onSubmit={formik.handleSubmit}>
                                            <Modal.Body>
                                                <h6>Leave a comment</h6>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Leave a comment here"
                                                    style={{ height: '100px' }}
                                                    value={formik.values.content}
                                                    onChange={formik.handleChange}
                                                    name="content"
                                                />
                                                <span style={{ color: "red" }}>
                                                    {formik.errors.content ? (
                                                        <div>{formik.errors.content} </div>
                                                    ) : null}{" "}
                                                </span>
                                                <Button variant="primary" type="submit" className='mt-4'>
                                                    Add Comment
                                                </Button>
                                            </Modal.Body>
                                        </Form>
                                    </Modal>
                                    <h4 className="blog_heading pe-4 mt-3">
                                        {blog?.title}
                                    </h4>
                                    <p className="mb-0 blog_description">
                                        {blog?.description}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {
                    blog?.comments?.length > 0 &&
                    <Container>
                        <div className="comments-section mb-5 bg-white shadow rounded-4">
                            <h4 className='mb-4'>Comments</h4>

                            <ul className="comments list-unstyled p-0 m-0">
                                {
                                    blog?.comments.map((item) => {
                                        return (<li key={item?._id}>
                                            <div className="comment d-flex gap-4 w-100 pb-3">
                                                <div className="comment-author">
                                                    <a href="#">
                                                        {
                                                            item?.commentor?.profile_image ? <img
                                                                src={`${process.env.REACT_APP_SERVER_URL}/uploads/users/original/${item?.commentor?.profile_image}`}
                                                                alt="investor"
                                                                className="rounded-circle"
                                                            /> : <img
                                                                src={require("../../images/testimonial-user.png")}
                                                                alt="investor"
                                                                className="rounded-circle"
                                                            />
                                                        }

                                                    </a>
                                                </div>
                                                <div className="comment-content pb-3 w-100">
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <div className="comment-meta-author">
                                                            {item?.commentor?.full_name}
                                                        </div>
                                                        <div className="comment-meta-date">
                                                            {moment(item?.createdAt).format('DD MMMM, YYYY')}
                                                        </div>
                                                    </div>
                                                    <div className="comment-body">
                                                        <p className='mb-0 text-muted'>{item?.content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>)
                                    })
                                }
                            </ul>
                        </div>
                    </Container>
                }

                <Container>
                    <Row>
                        {
                            blogs?.length > 0 && blogs.map((item) => {
                                return (<Col lg={4} className="mb-3" key={item?._id}>
                                    <div className="p-3 position-relative">
                                        <div className="blog_img">
                                            {
                                                item?.image ? <img
                                                    src={`${process.env.REACT_APP_SERVER_URL}/uploads/blogs/${item?.image}`}
                                                    alt="blog"
                                                    className="w-100"
                                                /> :
                                                    <img
                                                        src={require("../../images/faq.png")}
                                                        alt="blog"
                                                        className="w-100"
                                                    />
                                            }
                                        </div>
                                        <div className="blog_content_wrapper p-4 mx-auto">
                                            <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                                                <li className="d-flex gap-2 align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center admin_icon">
                                                        <FontAwesomeIcon icon={faUser} />
                                                    </div>
                                                    <p className="mb-0">{item?.user?.full_name}</p>
                                                </li>
                                                <li className="d-flex gap-2 align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center admin_icon">
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                    </div>
                                                    <p className="mb-0">{moment(item?.createdAt).format('DD MMMM, YYYY')}</p>
                                                </li>
                                                <li className="d-flex gap-2 align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center admin_icon">
                                                        <FontAwesomeIcon icon={faComment} />
                                                    </div>
                                                    <p className="mb-0">Comments ({item?.comments?.length})</p>
                                                </li>
                                            </ul>
                                            <h4 className="blog_heading pe-4">
                                                {item?.title}
                                            </h4>
                                            <p className="mb-0 blog_description">
                                                {
                                                    item?.description?.length > 150 ? item?.description.substring(0, 150) + '...' : item?.description
                                                }
                                            </p>
                                            <Link to={`/blog-detail/${item?._id}`} className="blog_btn px-4 mt-4 btn btn-primary">Read More</Link>

                                        </div>
                                    </div>
                                </Col>)
                            })
                        }
                    </Row>
                </Container>
            </div >
            <Footer />
        </>
    )
}

export default BLogDetail