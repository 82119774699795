import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;

export const getTransactionsThunk = createAsyncThunk(
    'transactions/get',
    async ({ headers, params }) => {
        const config = {
            headers: { ...headers },
            params: params
        }
        try {
            const data = await axios.get(`${api_url}/transactions/list`, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const getTransactionStatsThunk = createAsyncThunk(
    'transactions/stats',
    async ({ headers, params }) => {
        const config = {
            headers: { ...headers },
            params: params
        }
        try {
            const data = await axios.get(`${api_url}/transactions/stats`, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const getPropertyStatsThunk = createAsyncThunk(
    'transactions/propertyStats',
    async ({ headers, params }) => {
        const config = {
            headers: { ...headers },
            params: params
        }
        try {
            const data = await axios.get(`${api_url}/transactions/propertyStats`, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const addTransactionThunk = createAsyncThunk(
    'transactions/add',
    async ({ body, headers }) => {
        const config = {
            headers: { ...headers }
        }
        try {
            const data = await axios.post(`${api_url}/transactions/create`, body, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const addPaymentThunk = createAsyncThunk(
    'payment/add',
    async ({ body, headers }) => {
        const config = {
            headers: { ...headers }
        }
        try {
            const data = await axios.post(`${api_url}/payments/create`, body, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const payoutThunk = createAsyncThunk(
    'payment/payout',
    async ({ body, headers }) => {
        const config = {
            headers: { ...headers }
        }
        try {
            const data = await axios.post(`${api_url}/payments/payout`, body, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const approvedPayoutThunk = createAsyncThunk(
    'payout/approved',
    async ({ headers }) => {
        const config = {
            headers: { ...headers }
        }
        try {
            const data = await axios.get(`${api_url}/payments/approvedPayouts`, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const withdrawPayoutThunk = createAsyncThunk(
    'payout/withdraw',
    async ({ body, headers }) => {
        const config = {
            headers: { ...headers }
        }
        try {
            const data = await axios.post(`${api_url}/payments/withdrawPayouts`, body, config)
            return data

        } catch (error) {
            return error

        }
    }
)