export const investmentSelector = (state) => {
    const {
        investments,
        total,
        isLoading,
        limit,
        page
    } = state.investment

    return {
        investments,
        total,
        isLoading,
        limit,
        page
    }
}