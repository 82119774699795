export const categorySelector = (state) => {
    const {
        categories,
        total,
        isLoading,
        limit,
        page
    } = state.category

    return {
        categories,
        total,
        isLoading,
        limit,
        page
    }
}