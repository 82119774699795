import React, { useState } from "react";
import {
  Accordion,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Header from "../common/header";
import Footer from "../common/footer";
import {
  faBuilding,
  faCalendar,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowPointer,
  faArrowRight,
  faBagShopping,
  faCheck,
  faCompassDrafting,
  faHouseChimneyWindow,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import { subscriberSchema } from "../../utils/validationsSchema";
import { subscribeThunk } from "../../features/auth/authThunk";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";

function LandingPage() {
  const dispatch = useDispatch();
  var apartmentSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var testimonials = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const [show, setShow] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: subscriberSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await dispatch(subscribeThunk(values));
        if (response?.payload?.status === 200) {
          toast.success('Subcribed successfully');
          resetForm();
        } else {
          toast.error('Email already subscribed')
        }
      } catch (error) {
        toast.error("Something went wrong.", {
          position: "bottom-left",
        });
      }
    },
  });


  return (
    <>
      <Header />
      <div className="landingBanner position-relative">
        <Container>
          <Row className="align-items-center">
            <Col lg={7}>
              <div className="landingBanner-info position-relative z-1 ">
                <h1 className="banner-heading mb-4">
                  Invest Globally in Prime Real Estate with as Little as 10€
                  {/* <span className="bannerHeadingshape  position-relative">
                    {" "}
                    Investments{" "}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="191"
                        height="11"
                        viewBox="0 0 191 11"
                        fill="none"
                        className="shape-svg"
                      >
                        <path
                          d="M189.5 7.03716C177.1 0.237162 39.5 -4 0 7.03716C24.5 5.93592 182 9.53717 187 10.0372C192 10.5372 191 7.03716 189.5 7.03716Z"
                          fill="#55BCC9"
                        />
                      </svg>
                    </span>
                  </span>{" "} */}
                </h1>
                {/* <div className=" position-relative mb-4">
                  <p className="banner-subheading pe-3">
                    Enjoy the high returns of real estate investing without the burden of paperwork, property management -- Crowdfina: Your hassle-free investment journey.
                    <span className="bannersubHeadingshape d-none d-xl-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="92"
                        height="57"
                        viewBox="0 0 92 57"
                        fill="none"
                      >
                        <path
                          d="M2 2C3.22535 24.669 12.4155 46.1297 27.7324 40.5986C50.4014 32.4126 47.7828 19.6887 44.2746 15.4789C41.2113 11.8028 32.6338 6.90141 21.6056 19.7676C8.76615 34.747 26.7637 47.5947 27.7324 48.5634C28.7011 49.5321 46.7254 59.5916 70.6197 51.0141C84.0986 46.1755 86.1408 39.9859 89 37.5352M89 37.5352L84.7113 54.0775M89 37.5352L71.8451 35.0845"
                          stroke="#55BCC9"
                          strokeWidth="3.67606"
                          strokeLinecap="round"
                        />
                      </svg>
                    </span>
                  </p>
                </div> */}
                <div className="mb-4">
                  <form action="">
                    <Row>
                      <Col md={7}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="email"
                            placeholder="Your Email address"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={5}>
                        <Button>Get Started</Button>
                      </Col>
                    </Row>
                  </form>
                </div>
                <Card className="counter-card">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={4}>
                        <div className="banner-counters position-relative text-center">
                          {/* <h4>+US$3.5M</h4> */}
                          <p className="mb-0">Invest from 10€</p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="banner-counters position-relative text-center">
                          {/* <h4>485K</h4> */}
                          <p className="mb-0">
                            High Return Target 5 to 10%
                          </p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="banner-counters position-relative text-center no-after">
                          {/* <h4>US$120M</h4> */}
                          <p className="mb-0">
                            Hasselfree worldwide investment
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col lg={5}>
              <div className="banner-image">
                <img
                  src={require("../../images/landing-banner.png")}
                  alt="img"
                />
              </div>
              <div className="d-none d-lg-block revenue-card">
                <Card className="border-0">
                  <CardBody>
                    <img
                      src={require("../../images/revenue-icon.png")}
                      alt="revenue-icon"
                      className="mb-3"
                    />
                    <h4>$7454.21</h4>
                    <p className="mb-0">Revenue</p>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="investor-section bg-white mb-4">
        <Container>
          <Row className="justify-content-between investor-list">
            <Col
              lg={2}
              md={3}
              sm={4}
              className="d-flex justify-content-center align-items-center py-4"
            >
              <img src={require("../../images/investor1.png")} alt="img" />
            </Col>
            <Col lg={2} md={3} sm={4} className="text-center py-4">
              <img src={require("../../images/investor2.png")} alt="img" />
            </Col>
            <Col lg={2} md={3} sm={4} className="text-center py-4">
              <img src={require("../../images/investor3.png")} alt="img" />
            </Col>
            <Col lg={2} md={3} sm={4} className="text-center py-4">
              <img src={require("../../images/investor4.png")} alt="img" />
            </Col>
            <Col lg={2} md={3} sm={4} className="text-center py-4">
              <img src={require("../../images/investor5.png")} alt="img" />
            </Col>
          </Row>
        </Container>
      </div> */}

      {/* <div className="investorSearchCTA mb-5">
        <Container>
          <div className="position-relative top-spacing">
            <div className="darkCard p-3 p-sm-5">
              <div className="propertyImg">
                <img
                  src={require("../../images/landing-banner.png")}
                  alt=""
                  className="object-fit-cover"
                />
              </div>
              <Row>
                <Col lg={6} className="position-relative">
                  <div className="recognised_investors">
                    <Card className="border-0">
                      <CardBody className="py-4 px-3">
                        <div className="d-flex align-items-center mb-3">
                          <div>
                            <img
                              src={require("../../images/authbanner.png")}
                              alt=""
                              className="invertor-img"
                            />
                          </div>
                          <div>
                            <img
                              src={require("../../images/authbanner.png")}
                              alt=""
                              className="invertor-img"
                            />
                          </div>
                          <div>
                            <img
                              src={require("../../images/authbanner.png")}
                              alt=""
                              className="invertor-img"
                            />
                          </div>
                          <div>
                            <img
                              src={require("../../images/authbanner.png")}
                              alt=""
                              className="invertor-img"
                            />
                          </div>
                          <div className="add_img-icon d-flex align-items-center justify-content-center">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.8614 3.90039C12.1719 3.90039 12.4697 4.02372 12.6892 4.24326C12.9087 4.4628 13.0321 4.76055 13.0321 5.07103V10.9242H18.8852C19.1957 10.9242 19.4935 11.0475 19.713 11.2671C19.9325 11.4866 20.0559 11.7844 20.0559 12.0948C20.0559 12.4053 19.9325 12.7031 19.713 12.9226C19.4935 13.1421 19.1957 13.2655 18.8852 13.2655H13.0321V19.1186C13.0321 19.4291 12.9087 19.7269 12.6892 19.9464C12.4697 20.1659 12.1719 20.2893 11.8614 20.2893C11.551 20.2893 11.2532 20.1659 11.0337 19.9464C10.8141 19.7269 10.6908 19.4291 10.6908 19.1186V13.2655H4.83763C4.52716 13.2655 4.2294 13.1421 4.00986 12.9226C3.79033 12.7031 3.66699 12.4053 3.66699 12.0948C3.66699 11.7844 3.79033 11.4866 4.00986 11.2671C4.2294 11.0475 4.52716 10.9242 4.83763 10.9242H10.6908V5.07103C10.6908 4.76055 10.8141 4.4628 11.0337 4.24326C11.2532 4.02372 11.551 3.90039 11.8614 3.90039Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        <p className="text-center investor-text mb-0">
                          10k+ Registered investors
                        </p>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="darkCTA-text">
                    <h4>You invest in the apartment of your choice</h4>
                    <div className="d-flex gap-3 search-property">
                      <div className="position-relative flex-grow-1">
                        <Form.Group controlId="exampleForm.ControlInput2">
                          <Form.Control
                            type="text"
                            placeholder="Search your property"
                            className="ps-5"
                          />
                        </Form.Group>
                        <span className="placeholder-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                          >
                            <path
                              d="M11.7494 4.54967C11.6825 4.48569 11.5935 4.44998 11.501 4.44998C11.4084 4.44998 11.3194 4.48569 11.2525 4.54967L2.98242 12.4501C2.9473 12.4837 2.91936 12.524 2.90029 12.5688C2.88121 12.6135 2.87141 12.6616 2.87146 12.7102L2.87012 20.125C2.87012 20.5062 3.02157 20.8719 3.29115 21.1414C3.56073 21.411 3.92637 21.5625 4.30762 21.5625H8.62461C8.81523 21.5625 8.99805 21.4868 9.13284 21.352C9.26763 21.2172 9.34336 21.0344 9.34336 20.8437V14.7344C9.34336 14.639 9.38122 14.5476 9.44862 14.4802C9.51601 14.4128 9.60742 14.375 9.70274 14.375H13.2965C13.3918 14.375 13.4832 14.4128 13.5506 14.4802C13.618 14.5476 13.6559 14.639 13.6559 14.7344V20.8437C13.6559 21.0344 13.7316 21.2172 13.8664 21.352C14.0012 21.4868 14.184 21.5625 14.3746 21.5625H18.6898C19.0711 21.5625 19.4367 21.411 19.7063 21.1414C19.9759 20.8719 20.1273 20.5062 20.1273 20.125V12.7102C20.1274 12.6616 20.1176 12.6135 20.0985 12.5688C20.0794 12.524 20.0515 12.4837 20.0163 12.4501L11.7494 4.54967Z"
                              fill="black"
                            />
                            <path
                              d="M22.0526 10.9677L18.6925 7.75307V2.875C18.6925 2.68438 18.6167 2.50156 18.4819 2.36677C18.3471 2.23198 18.1643 2.15625 17.9737 2.15625H15.8175C15.6268 2.15625 15.444 2.23198 15.3092 2.36677C15.1744 2.50156 15.0987 2.68438 15.0987 2.875V4.3125L12.4968 1.82473C12.2534 1.57855 11.8913 1.4375 11.5 1.4375C11.1101 1.4375 10.7489 1.57855 10.5054 1.82518L0.950564 10.9668C0.67115 11.2363 0.636111 11.6797 0.890369 11.9717C0.954217 12.0454 1.03239 12.1053 1.12014 12.1479C1.20789 12.1904 1.30338 12.2146 1.40079 12.2191C1.4982 12.2235 1.5955 12.2081 1.68676 12.1738C1.77803 12.1394 1.86135 12.0869 1.93166 12.0193L11.2529 3.11219C11.3198 3.04821 11.4088 3.0125 11.5014 3.0125C11.5939 3.0125 11.6829 3.04821 11.7498 3.11219L21.072 12.0193C21.2093 12.151 21.3932 12.2229 21.5834 12.2191C21.7737 12.2154 21.9547 12.1365 22.0868 11.9995C22.3626 11.7138 22.3397 11.2421 22.0526 10.9677Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </div>
                      <div>
                        <Button className="send_iconbtn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_582_1871)">
                              <path
                                d="M28.6656 8.05513C29.2776 6.36221 27.6371 4.72172 25.9442 5.33513L5.25378 12.818C3.5552 13.4328 3.34978 15.7505 4.91237 16.6557L11.5169 20.4793L17.4145 14.5817C17.6816 14.3237 18.0395 14.1809 18.4109 14.1841C18.7824 14.1873 19.1377 14.3363 19.4004 14.599C19.663 14.8616 19.812 15.217 19.8152 15.5884C19.8185 15.9598 19.6757 16.3177 19.4176 16.5849L13.52 22.4825L17.345 29.087C18.2489 30.6495 20.5665 30.4427 21.1814 28.7455L28.6656 8.05513Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_582_1871">
                                <rect width="34" height="34" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div> */}
      <div className="common-padding apartment-section">
        <Container>
          <p className="common-badge">Apartment</p>
          <Row>
            <Col lg={8}>
              <div className="common-heading-section mb-5 mb-lg-0">
                <h2>Enjoy consistent Year-Over-Year Asset Performance</h2>
                {/* <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p> */}
              </div>
            </Col>
          </Row>
          <Slider {...apartmentSettings}>
            <div>
              <div className="assets_performance-card">
                <Card className="border-0">
                  <CardBody>
                    <div className="top-img mb-4">
                      <img
                        src={require("../../images/authbanner.png")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between flex-wrap ROI_incom">
                      <p className="exptd-incm">Expected Income</p>
                      <p className="rate">9.88 %</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between flex-wrap ROI_incom">
                      <p className="exptd-incm">Capital ROI</p>
                      <p className="rate">+12.90%</p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div>
              <div className="assets_performance-card">
                <Card className="border-0">
                  <CardBody>
                    <div className="top-img mb-4">
                      <img
                        src={require("../../images/authbanner.png")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between flex-wrap ROI_incom">
                      <p className="exptd-incm">Expected Income</p>
                      <p className="rate">9.88 %</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between flex-wrap ROI_incom">
                      <p className="exptd-incm">Capital ROI</p>
                      <p className="rate">+12.90%</p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>

            <div>
              <div className="assets_performance-card">
                <Card className="border-0">
                  <CardBody>
                    <div className="top-img mb-4">
                      <img
                        src={require("../../images/authbanner.png")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between flex-wrap ROI_incom">
                      <p className="exptd-incm">Expected Income</p>
                      <p className="rate">9.88 %</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between flex-wrap ROI_incom">
                      <p className="exptd-incm">Capital ROI</p>
                      <p className="rate">+12.90%</p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div>
              <div className="assets_performance-card">
                <Card className="border-0">
                  <CardBody>
                    <div className="top-img mb-4">
                      <img
                        src={require("../../images/authbanner.png")}
                        alt=""
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between flex-wrap ROI_incom">
                      <p className="exptd-incm">Expected Income</p>
                      <p className="rate">9.88 %</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between flex-wrap ROI_incom">
                      <p className="exptd-incm">Capital ROI</p>
                      <p className="rate">+12.90%</p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Slider>
        </Container>
      </div>

      {/* <div className="about-us-section ">
        <Container>
          <Row>
            <Col lg={6}>
              <p className="common-badge">About Us</p>
              <div className="common-heading-section">
                <h2>We Specialise In Quality Lorem ipsum</h2>
                <p className="pe-lg-5">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its
                  layout.It is a long established fact that a reader will be
                  distracted by the.
                </p>
                <ul className="list-unstyled">
                  <li className="mb-4 d-flex gap-3 align-items-center">
                    <span className="about-lists-check d-flex align-items-center justify-content-center rounded-circle">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    Outstanding Property
                  </li>
                  <li className="mb-4 d-flex gap-3 align-items-center">
                    <span className="about-lists-check d-flex align-items-center justify-content-center rounded-circle">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    Provide best Services to users
                  </li>
                  <li className="mb-4 d-flex gap-3 align-items-center">
                    <span className="about-lists-check d-flex align-items-center justify-content-center rounded-circle">
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    Good investment
                  </li>
                </ul>
              </div>
              <Button className="px-4">Read More</Button>
            </Col>
            <Col lg={6}>
              <Row>
                <Col sm={6}>
                  <div className="pt-5 mb-4 about-us-img smaller-one">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="113"
                        height="80"
                        viewBox="0 0 113 80"
                        fill="none"
                      >
                        <path
                          d="M3 16.2017C25.8182 1.74326 52.9625 -4.16109 58.1731 14.5027C65.8846 42.1252 51.7444 48.3691 45.2426 47.8743C39.5651 47.4422 28.8942 42.5113 33.7534 22.9417C39.4108 0.158009 64.2855 8.73511 65.8931 9.00546C67.5007 9.27581 89.6913 19.8032 97.8692 48.8836C102.482 65.2878 97.8926 71.5394 97.4905 76.0022M97.4905 76.0022L110.564 60.4278M97.4905 76.0022L83.2749 61.0658"
                          stroke="#55BCC9"
                          strokeWidth="4.37414"
                          strokeLinecap="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="about-us-img bigger-one mb-4 mb-sm-0">
                    <img
                      src={require("../../images/authbanner.png")}
                      alt=""
                      className="object-fit-cover h-100 w-100"
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="about-us-img bigger-one mb-4">
                    <img
                      src={require("../../images/landing-banner.png")}
                      alt=""
                      className="object-fit-cover h-100 w-100"
                    />
                  </div>
                  <div className="about-us-img smaller-one">
                    <img
                      src={require("../../images/landing-banner.png")}
                      alt=""
                      className="object-fit-cover h-100 w-100"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className="how-we-works common-padding">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={7}>
              <div className="text-center">
                <p className="common-badge text-center">How Crowdfina Works</p>
              </div>
              <div className="common-heading-section">
                <h2 className="text-center">
                  How crowdfina works and the Process of Investments
                </h2>
              </div>
            </Col>
          </Row>
          <div className="common-padding pb-0">
            <Row>
              {/* <Col lg={4} className="mb-4">
                <div className="single-works-card">
                  <div className="icon d-flex align-items-center justify-content-center mx-auto mb-4">
                    <FontAwesomeIcon icon={faBuilding} />
                  </div>
                  <h5 className="text-center text-black">Luxury Apartment</h5>
                  <p className="text-center text-black">
                    It is a long established fact that a reader will be
                    distracted by the readable.
                  </p>
                </div>
              </Col>
              <Col lg={4} className="mb-4">
                <div className="single-works-card">
                  <div className="icon d-flex align-items-center justify-content-center mx-auto mb-4">
                    <FontAwesomeIcon icon={faCompassDrafting} />
                  </div>
                  <h5 className="text-center text-black">
                    Architecture Design
                  </h5>
                  <p className="text-center text-black">
                    It is a long established fact that a reader will be
                    distracted by the readable.
                  </p>
                </div>
              </Col>
              <Col lg={4} className="mb-4">
                <div className="single-works-card">
                  <div className="icon d-flex align-items-center justify-content-center mx-auto mb-4">
                    <FontAwesomeIcon icon={faHouseChimneyWindow} />
                  </div>
                  <h5 className="text-center text-black">Browse Property</h5>
                  <p className="text-center text-black">
                    It is a long established fact that a reader will be
                    distracted by the readable.
                  </p>
                </div>
              </Col> */}
              <Col lg={4} className="mb-4">
                <div className="single-works-card h-100">
                  <div className="icon d-flex align-items-center justify-content-center mx-auto mb-4">
                    <FontAwesomeIcon icon={faBagShopping} />
                  </div>
                  <h5 className="text-center text-black">Buy Crowdys</h5>
                  <p className="text-center text-black">
                    Investors start by logging onto the Crowdfina platform and purchasing Crowdys, our unique tokens that enable investment in real estate.
                  </p>
                </div>
              </Col>

              <Col lg={4} className="mb-4">
                <div className="single-works-card h-100">
                  <div className="icon d-flex align-items-center justify-content-center mx-auto mb-4">
                    <FontAwesomeIcon icon={faArrowPointer} />
                  </div>
                  <h5 className="text-center text-black">Select a property</h5>
                  <p className="text-center text-black">
                    Explore a curated selection of properties worldwide, and choose the ones that best fit your investment goals.
                  </p>
                </div>
              </Col>
              <Col lg={4} className="mb-4">
                <div className="single-works-card h-100">
                  <div className="icon d-flex align-items-center justify-content-center mx-auto mb-4">
                    <FontAwesomeIcon icon={faSackDollar} />
                  </div>
                  <h5 className="text-center text-black">Invest and Enjoy Returns</h5>
                  <p className="text-center text-black">
                    Decide how much you want to invest in your selected properties and start enjoying the potential returns on your investment.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      {/* <div className="blogs-section common-padding pt-0">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={7}>
              <div className="text-center">
                <p className="common-badge text-center">Blogs</p>
              </div>
              <div className="common-heading-section mb-5">
                <h2 className="text-center">Our Trending Blogs</h2>
                <p className="text-center">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <div className="blog-card mb-4 mb-lg-0">
                <img
                  src={require("../../images/authbanner.png")}
                  className="blog-img img-fluid w-100"
                />
                <div className="p-3">
                  <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
                    <p className="text-muted mb-2">
                      <FontAwesomeIcon icon={faUser} />
                      &nbsp; John Doe
                    </p>
                    <p className="text-muted mb-2">
                      <FontAwesomeIcon icon={faCalendar} />
                      &nbsp; 21 - Nov -2023
                    </p>
                  </div>
                  <h6 className="text-black blogCard-heading">
                    Why investing in property is so easy and profitable
                  </h6>
                  <Link className="read-more ">
                    Read More
                    <span className="text-success ms-1 mt-2 rotate-read-arrow d-inline-block">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>{" "}
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-card mb-4 mb-lg-0">
                <img
                  src={require("../../images/landing-banner.png")}
                  className="blog-img img-fluid w-100"
                />
                <div className="p-3">
                  <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
                    <p className="text-muted mb-2">
                      <FontAwesomeIcon icon={faUser} />
                      &nbsp; John Doe
                    </p>
                    <p className="text-muted mb-2">
                      <FontAwesomeIcon icon={faCalendar} />
                      &nbsp; 21 - Nov -2023
                    </p>
                  </div>
                  <h6 className="text-black blogCard-heading">
                    Why investing in property is so easy and profitable
                  </h6>
                  <Link className="read-more ">
                    Read More
                    <span className="text-success ms-1 mt-2 rotate-read-arrow d-inline-block">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>{" "}
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-card mb-4 mb-lg-0">
                <img
                  src={require("../../images/authbanner.png")}
                  className="blog-img img-fluid w-100"
                />
                <div className="p-3">
                  <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
                    <p className="text-muted mb-2">
                      <FontAwesomeIcon icon={faUser} />
                      &nbsp; John Doe
                    </p>
                    <p className="text-muted mb-2">
                      <FontAwesomeIcon icon={faCalendar} />
                      &nbsp; 21 - Nov -2023
                    </p>
                  </div>
                  <h6 className="text-black blogCard-heading">
                    Why investing in property is so easy and profitable
                  </h6>
                  <Link className="read-more ">
                    Read More
                    <span className="text-success ms-1 mt-2 rotate-read-arrow d-inline-block">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="common-padding bg-black download-app-section">
        <Container>
          <Row>
            <Col lg={7}>
              <div className="pe-4 mb-4">
                <h3 className="text-white font-semibold mb-4 app-downld-heading">
                  <span className="text-success">CrowdFina</span> App is
                  available on App Store & Google play{" "}
                </h3>
                <p className="text-white mb-5 app-downld-subtitle">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
                <h5 className="text-white mb-4">Download App from here</h5>
                <div className="d-flex gap-3">
                  <Link>
                    <img src={require("../../images/app_store.png")} />
                  </Link>
                  <Link>
                    <img src={require("../../images/google_play.png")} />
                  </Link>
                </div>
              </div>
            </Col>

            <Col lg={5}>
              <div className="position-relative">
                <div className="app-download-shape d-none d-lg-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="97"
                    height="92"
                    viewBox="0 0 97 92"
                    fill="none"
                  >
                    <path
                      d="M5.00098 88.9346C-1.00809 62.5981 2.49047 35.0403 21.8229 36.3611C50.435 38.3158 51.5987 53.7294 48.9613 59.6927C46.6582 64.9 38.447 73.312 21.6233 62.197C2.03648 49.2564 18.4274 28.6735 19.2191 27.2485C20.0107 25.8234 37.3437 8.42182 67.4857 10.4238C84.4888 11.5531 88.8489 17.967 92.9213 19.8362M92.9213 19.8362L82.6065 2.3121M92.9213 19.8362L74.0953 28.2484"
                      stroke="#4BA62B"
                      strokeWidth="4.37414"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <div className="ps-lg-5">
                  <img
                    src={require("../../images/app-download-img.png")}
                    alt=""
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="testimonial-section common-padding">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={7}>
              <div className="text-center">
                <p className="common-badge text-center">Testimonials</p>
              </div>
              <div className="common-heading-section mb-5">
                <h2 className="text-center">What our client says</h2>
                <p className="text-center">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout
                </p>
              </div>
            </Col>
          </Row>
          <Slider {...testimonials}>
            <div>
              <Card className="border-0 testimonial-card position-relative mx-3 my-4">
                <CardBody>
                  <div className="d-flex mb-4">
                    <div className="textimonials-user d-flex align-items-center gap-3 pe-4">
                      <img
                        src={require("../../images/testimonial-user.png")}
                        alt=""
                      />
                      <div>
                        <h4 className="name mb-1">Cameron Williamson</h4>
                        <p className="designation mb-0">Ceo & Founder</p>
                      </div>
                    </div>
                  </div>
                  <p className="message">
                    It is a long established fact that a reader will be
                    distracted by the readable.It is a long established fact
                    that a reader will be distracted.
                  </p>
                  <div className="testimonials-shape">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="53"
                      height="53"
                      viewBox="0 0 53 53"
                      fill="none"
                    >
                      <path
                        d="M42.8788 14.7496C45.1534 17.1655 46.3746 19.8752 46.3746 24.2675C46.3746 31.9967 40.9509 38.9242 33.0583 42.3494L31.0863 39.3063C38.4511 35.3225 39.8909 30.1527 40.4673 26.8932C39.2814 27.5072 37.729 27.7214 36.2052 27.58C32.2214 27.2112 29.0811 23.9407 29.0811 19.8752C29.0811 17.8253 29.8954 15.8593 31.3449 14.4098C32.7944 12.9603 34.7604 12.146 36.8103 12.146C39.1798 12.146 41.4478 13.2281 42.8788 14.7496ZM20.7955 14.7496C23.07 17.1655 24.2913 19.8752 24.2913 24.2675C24.2913 31.9967 18.8676 38.9242 10.975 42.3494L9.00296 39.3063C16.3678 35.3225 17.8076 30.1527 18.3818 26.8932C17.1959 27.5072 15.6434 27.7214 14.1219 27.58C10.138 27.2112 7 23.9407 7 19.8752C7 17.8253 7.81432 15.8593 9.26382 14.4098C10.7133 12.9603 12.6793 12.146 14.7292 12.146C17.0987 12.146 19.3667 13.2281 20.7977 14.7496H20.7955Z"
                        fill="#BEFFB8"
                      />
                    </svg>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card className="border-0 testimonial-card position-relative mx-3 my-4">
                <CardBody>
                  <div className="d-flex mb-4">
                    <div className="textimonials-user d-flex align-items-center gap-3 pe-4">
                      <img
                        src={require("../../images/testimonial-user.png")}
                        alt=""
                      />
                      <div>
                        <h4 className="name mb-1">Cameron Williamson</h4>
                        <p className="designation mb-0">Ceo & Founder</p>
                      </div>
                    </div>
                  </div>
                  <p className="message">
                    It is a long established fact that a reader will be
                    distracted by the readable.It is a long established fact
                    that a reader will be distracted.
                  </p>
                  <div className="testimonials-shape">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="53"
                      height="53"
                      viewBox="0 0 53 53"
                      fill="none"
                    >
                      <path
                        d="M42.8788 14.7496C45.1534 17.1655 46.3746 19.8752 46.3746 24.2675C46.3746 31.9967 40.9509 38.9242 33.0583 42.3494L31.0863 39.3063C38.4511 35.3225 39.8909 30.1527 40.4673 26.8932C39.2814 27.5072 37.729 27.7214 36.2052 27.58C32.2214 27.2112 29.0811 23.9407 29.0811 19.8752C29.0811 17.8253 29.8954 15.8593 31.3449 14.4098C32.7944 12.9603 34.7604 12.146 36.8103 12.146C39.1798 12.146 41.4478 13.2281 42.8788 14.7496ZM20.7955 14.7496C23.07 17.1655 24.2913 19.8752 24.2913 24.2675C24.2913 31.9967 18.8676 38.9242 10.975 42.3494L9.00296 39.3063C16.3678 35.3225 17.8076 30.1527 18.3818 26.8932C17.1959 27.5072 15.6434 27.7214 14.1219 27.58C10.138 27.2112 7 23.9407 7 19.8752C7 17.8253 7.81432 15.8593 9.26382 14.4098C10.7133 12.9603 12.6793 12.146 14.7292 12.146C17.0987 12.146 19.3667 13.2281 20.7977 14.7496H20.7955Z"
                        fill="#BEFFB8"
                      />
                    </svg>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card className="border-0 testimonial-card position-relative mx-3 my-4">
                <CardBody>
                  <div className="d-flex mb-4">
                    <div className="textimonials-user d-flex align-items-center gap-3 pe-4">
                      <img
                        src={require("../../images/testimonial-user.png")}
                        alt=""
                      />
                      <div>
                        <h4 className="name mb-1">Cameron Williamson</h4>
                        <p className="designation mb-0">Ceo & Founder</p>
                      </div>
                    </div>
                  </div>
                  <p className="message">
                    It is a long established fact that a reader will be
                    distracted by the readable.It is a long established fact
                    that a reader will be distracted.
                  </p>
                  <div className="testimonials-shape">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="53"
                      height="53"
                      viewBox="0 0 53 53"
                      fill="none"
                    >
                      <path
                        d="M42.8788 14.7496C45.1534 17.1655 46.3746 19.8752 46.3746 24.2675C46.3746 31.9967 40.9509 38.9242 33.0583 42.3494L31.0863 39.3063C38.4511 35.3225 39.8909 30.1527 40.4673 26.8932C39.2814 27.5072 37.729 27.7214 36.2052 27.58C32.2214 27.2112 29.0811 23.9407 29.0811 19.8752C29.0811 17.8253 29.8954 15.8593 31.3449 14.4098C32.7944 12.9603 34.7604 12.146 36.8103 12.146C39.1798 12.146 41.4478 13.2281 42.8788 14.7496ZM20.7955 14.7496C23.07 17.1655 24.2913 19.8752 24.2913 24.2675C24.2913 31.9967 18.8676 38.9242 10.975 42.3494L9.00296 39.3063C16.3678 35.3225 17.8076 30.1527 18.3818 26.8932C17.1959 27.5072 15.6434 27.7214 14.1219 27.58C10.138 27.2112 7 23.9407 7 19.8752C7 17.8253 7.81432 15.8593 9.26382 14.4098C10.7133 12.9603 12.6793 12.146 14.7292 12.146C17.0987 12.146 19.3667 13.2281 20.7977 14.7496H20.7955Z"
                        fill="#BEFFB8"
                      />
                    </svg>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card className="border-0 testimonial-card position-relative mx-3 my-4">
                <CardBody>
                  <div className="d-flex mb-4">
                    <div className="textimonials-user d-flex align-items-center gap-3 pe-4">
                      <img
                        src={require("../../images/testimonial-user.png")}
                        alt=""
                      />
                      <div>
                        <h4 className="name mb-1">Cameron Williamson</h4>
                        <p className="designation mb-0">Ceo & Founder</p>
                      </div>
                    </div>
                  </div>
                  <p className="message">
                    It is a long established fact that a reader will be
                    distracted by the readable.It is a long established fact
                    that a reader will be distracted.
                  </p>
                  <div className="testimonials-shape">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="53"
                      height="53"
                      viewBox="0 0 53 53"
                      fill="none"
                    >
                      <path
                        d="M42.8788 14.7496C45.1534 17.1655 46.3746 19.8752 46.3746 24.2675C46.3746 31.9967 40.9509 38.9242 33.0583 42.3494L31.0863 39.3063C38.4511 35.3225 39.8909 30.1527 40.4673 26.8932C39.2814 27.5072 37.729 27.7214 36.2052 27.58C32.2214 27.2112 29.0811 23.9407 29.0811 19.8752C29.0811 17.8253 29.8954 15.8593 31.3449 14.4098C32.7944 12.9603 34.7604 12.146 36.8103 12.146C39.1798 12.146 41.4478 13.2281 42.8788 14.7496ZM20.7955 14.7496C23.07 17.1655 24.2913 19.8752 24.2913 24.2675C24.2913 31.9967 18.8676 38.9242 10.975 42.3494L9.00296 39.3063C16.3678 35.3225 17.8076 30.1527 18.3818 26.8932C17.1959 27.5072 15.6434 27.7214 14.1219 27.58C10.138 27.2112 7 23.9407 7 19.8752C7 17.8253 7.81432 15.8593 9.26382 14.4098C10.7133 12.9603 12.6793 12.146 14.7292 12.146C17.0987 12.146 19.3667 13.2281 20.7977 14.7496H20.7955Z"
                        fill="#BEFFB8"
                      />
                    </svg>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Slider>
        </Container>
      </div> */}

      {/* === faq section ==== */}
      {/* <div className=" common-padding pt-0">
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col lg={5} md={5}>
              <p className="common-badge">Faqs</p>
              <div className="common-heading-section pe-5">
                <h2>
                  Have Any Question? <br />
                  Find Answer
                </h2>
                <p className="pe-5">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout
                </p>
              </div>
              <div className="mt-4 faq-img mb-4 mb-md-0">
                <img src={require("../../images/faq.png")} alt="img" />
              </div>
            </Col>
            <Col lg={6} md={7}>
              <Accordion className="faq_accordians">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Many desktop publishing packages and web page editors
                  </Accordion.Header>
                  <Accordion.Body className="text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Various versions have evolved over the years
                  </Accordion.Header>
                  <Accordion.Body className="text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Lorem Ipsum used since the 1500s is reproduced
                  </Accordion.Header>
                  <Accordion.Body className="text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    It uses a dictionary of over 200 Latin words, combined
                  </Accordion.Header>
                  <Accordion.Body className="text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    Lorem Ipsum used since the 1500s is reproduced
                  </Accordion.Header>
                  <Accordion.Body className="text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    It uses a dictionary of over 200 Latin words, combined
                  </Accordion.Header>
                  <Accordion.Body className="text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div> */}

      {/* == footer == */}

      <Footer />

    </>
  );
}

export default LandingPage;
