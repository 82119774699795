import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
    getCardsThunk,
    addCardThunk
} from "./cardThunk";


const initialState = {
    cards: [],
    total: 0,
    isLoading: false,
    limit: 10,
    page: 1,
};

const cardSlice = createSlice({
    name: "card",
    initialState,
    reducers: {
        changeLimit: (state, action) => {
            state.limit = action.payload;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCardsThunk.fulfilled, (state, action) => {
                if (action?.payload?.status === 200) {
                    state.cards = action?.payload?.data?.body?.data;
                    state.total = action?.payload?.data?.body?.count;
                }
            })
        // .addCase(addCardThunk.fulfilled, (state, action) => {
        //     console.log(action?.payload)
        // })
    },
});

export const { changeLimit, changePage } = cardSlice.actions;
export default cardSlice.reducer;
