import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
const api_url = process.env.REACT_APP_API_URL_DEV;

export const getBlogsThunk = createAsyncThunk(
    'blogs/get',
    async ({ headers, params }) => {
        const config = {
            headers: { ...headers },
            params: params
        }
        try {
            const data = await axios.get(`${api_url}/blogs/list`, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const getBlogDetailThunk = createAsyncThunk(
    'blog/detail',
    async (blogId) => {
        try {
            const data = await axios.get(`${api_url}/blogs/${blogId}`)
            return data

        } catch (error) {
            return error

        }
    }
)

export const addBlogCommentThunk = createAsyncThunk(
    "add/blog/comment",
    async ({ body, headers }) => {
        try {
            const config = {
                headers: {
                    Authorization: headers.Authorization,
                },
            };
            const data = await axios.post(`${api_url}/blogs/comment`, body, config);
            return data;
        } catch (error) {
            return error;
        }
    }
);
