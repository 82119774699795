import React, { useEffect } from "react";
import Header from "../common/header";
import Footer from "../common/footer";
import { useNavigate } from "react-router-dom";
import { authSelector } from "../../features/auth/authSelector";
import { useDispatch, useSelector } from "react-redux";

export default function Dashboard() {
  const navigate = useNavigate();
  const { token, userData } = useSelector(authSelector);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }

  }, [token])


  return (
    <div>
      <Header />
      <div className="me-2">
        <h2>Dashboard Coming Soon...</h2>
        <p>
          {" "}
          Thank you for visiting our website! We are currently in the
          development phase and are excited to bring you a feature-packed
          dashboard soon. Our team is working diligently to create an optimal
          online experience for you. Stay tuned for updates, and we appreciate
          your patience and understanding as we build towards providing you with
          a seamless and enhanced website. For any inquiries or assistance,
          please feel free to reach out to us. We look forward to unveiling our
          new dashboard to you soon!
        </p>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
