import logo from './logo.svg';
import './App.scss';
import AppRoutes from './AppRoutes';
import { Toaster } from "react-hot-toast";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


function App() {
  return (
    <Elements stripe={stripePromise}>
      <AppRoutes />
      <Toaster />
    </Elements>
  );
}

export default App;
