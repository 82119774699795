export const faqSelector = (state) => {
    const {
        faqs,
        totalFaqs,
        isLoading,
        limit,
        page,
    } = state.faq

    return {
        faqs,
        totalFaqs,
        isLoading,
        limit,
        page,
    }
}