import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import Header from "../common/header";
import Footer from "../common/footer";

function ConatctUs() {
  return (
    <>
      <Header />

      {/* ====== Conatct us banner ======= */}
      <div className="static_banner position-relative" id="ConatctUs">
        <Row className="justify-content-center pb-5">
          <Col md={8} xs={12} className="text-center">
            <h3>Conatct Us</h3>
            
          </Col>
        </Row> 
        <img src={require("../../images/banner-wave.png")} alt="wave" className="static-banner-shape" />
      </div>
       <Row className="justify-content-center">
        <Col lg={5} md={7} xs={8}>
          <Card className="border-0 contactUs-card mb-5">
            <CardBody className="p-4">
              <h2 className="mt-5 mb-3 fw-semibold px-4 text-center"> Love to Hear from You, <br/> Get In Touch 👋</h2>
              <p className="text-center mb-5">Before sending a message, Please make sure you check our FAQ here. And add a link to the FAQ.</p>
              <Row>
                <Col md={6} className="mb-4">
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                  type="email" 
                  />
                </Form.Group> 
                </Col>
                <Col md={6} className="mb-4">
                <Form.Group>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                  type="email" 
                  />
                </Form.Group> 
                </Col>
                <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={5} />
                </Form.Group> 
                </Col>
                <Col md={12}>
                <Button className="login-btn px-4 w-100 btn btn-primary" style={{height:'54px'}}>Submit</Button>
                </Col>
              </Row>
            </CardBody>
          </Card>        
        </Col>
       </Row>
      <Footer />
    </>
  );
}

export default ConatctUs;
