import React, { useState, useEffect } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tab,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBath,
  faBed,
  faCircleInfo,
  faFolderPlus,
  faHouse,
  faImages,
  faLocationPin,
  faRuler,
} from "@fortawesome/free-solid-svg-icons";
import AdminNavBar from "../AdminNavBar";
import ImageGallery from "react-image-gallery";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { addTransactionThunk, getTransactionStatsThunk } from "../../../features/transaction/transactionThunk";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import { toast } from "react-hot-toast";
import { transactionSelector } from "../../../features/transaction/transactionSelector";
import { addInvestmentThunk } from "../../../features/investment/investmentThunk";
import { getProfileThunk } from "../../../features/auth/authThunk";
import Loader from "../../../Loader";
import AddMoney from "../Modal/AddMoney";
import { getPropertyDetailThunk } from "../../../features/property/propertyThunk";
import moment from "moment";

function PropertyDetail() {
  const [addMoney, setAddMoney] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const toggleSidebar = () => setShowContent(!showContent);
  const location = useLocation();
  const [property, setProperty] = useState(location?.state?.property);
  const { token, userData } = useSelector(authSelector);
  const { walletBalance } = useSelector(transactionSelector);
  const distributedSum = property?.distributed_revenues_amount * 12;
  const minShareAvg = ((property?.minimum_investment_amount / property?.property_value) * 100).toFixed(2);
  const cashflow = ((minShareAvg / 100) * distributedSum).toFixed(2);
  const [durationProfit, setDurationProfit] = useState((cashflow * property?.investment_duration));
  const [durationProfitAvg, setDurationProfitAvg] = useState(((cashflow * property?.investment_duration) / property?.minimum_investment_amount) * 100);
  const [monthlyProfit, setMonthlyProfit] = useState((cashflow * (1 / 12)));
  const [monthlyProfitAvg, setMonthlyProfitAvg] = useState(((cashflow * (1 / 12)) / property?.minimum_investment_amount) * 100);
  const [pendingInvestment, setPendingInvestment] = useState(0);

  const [configObject] = useState({
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

  const images = property?.property_images.length > 0 && property?.property_images.map((item) => {
    let image = {
      original: process.env.REACT_APP_SERVER_URL + "/uploads/properties/original/" + item?.image,
      thumbnail: process.env.REACT_APP_SERVER_URL + "/uploads/properties/thumbnail/" + item?.image,
    }
    return image;
  })

  const [rangeValue, setRangeValue] = useState(property?.minimum_investment_amount ?? 0); // Initial value is set to 50, you can set it to your preferred initial value

  const handleRangeChange = (e) => {
    const value = e.target.value;
    setRangeValue(value);

    const minShareAvg = ((value / property?.property_value) * 100).toFixed(2);
    const cashflow = ((minShareAvg / 100) * distributedSum).toFixed(2);
    setDurationProfit((cashflow * property?.investment_duration));
    //setDurationProfitAvg(((cashflow * property?.investment_duration) / value) * 100);
    setMonthlyProfit((cashflow * (1 / 12)));
    ///setMonthlyProfitAvg(((cashflow * (1 / 12)) / value) * 100);

  };

  const investMoney = async () => {

    const userResp = await dispatch(getProfileThunk({ ...configObject }));
    if (userResp?.payload?.status == 200 && userResp?.payload?.data?.body?.documentVerified) {
      if (pendingInvestment < rangeValue) {
        toast.error(`Only €${pendingInvestment} are pending for investment.`);
      } else {
        setLoading(true);
        const resp = await dispatch(addInvestmentThunk({
          ...configObject, body: {
            type: 'buy',
            propertyId: property?._id,
            status: 'completed',
            amount: rangeValue,
            tokenQuantity: rangeValue / 10,
            tokenAmount: 10
          }
        }));

        if (resp?.payload?.status === 200) {
          const response = await dispatch(addTransactionThunk({
            ...configObject, body: {
              type: 'withdraw',
              via: 'investment',
              status: 'completed',
              amount: rangeValue,
              propertyId: resp?.payload?.data?.body?._id
            }
          }));
          await dispatch(getProfileThunk({ ...configObject }));
          toast.success('Money invested successfully')

        } else {
          toast.error('Some problem exist. Please try again.');
        }
        setLoading(false);
      }
      setLoading(false);
    } else {
      toast.error('Your document verification is inprogress. After verified you can invest');
    }

  }

  const sliderValue = () => {
    const value = (100 * rangeValue) / property?.property_value;
    let dd = 5;
    if (value > 50) {
      dd = 15;
    } else if (value > 10 && value <= 50) {
      dd = 10;
    } else {
      dd = 5;
    }
    return dd;
  }

  useEffect(() => {
    getData();
    getPropertyDetail();
  }, [])

  const getData = async () => {
    await dispatch(getTransactionStatsThunk({ ...configObject }))
  }

  const getPropertyDetail = async () => {
    const resp = await dispatch(getPropertyDetailThunk({
      ...configObject, params: {
        id: property?._id
      }
    }))
    if (resp?.payload?.status === 200) {
      setProperty(resp?.payload?.data?.body);
      const pending = property?.property_value - resp?.payload?.data?.body?.totalInvested;
      setPendingInvestment(pending);
    }
  }

  const closeAddMoney = () => {
    getData();
    setAddMoney(false);
  }

  function calculateExpectedGain(initialValue, growthRate, years) {
    return initialValue * Math.pow((1 + growthRate / 100), years);
  }

  function isImageFile(fileName) {
    // Define a regular expression for image file extensions
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;

    // Test the file name against the regular expression
    return imageExtensions.test(fileName);
  }

  const tooltip = (
    <Tooltip id="tooltip">
      This rate determines the portion of annual rental income distributed to investors after deducting the platform fee. Investors receive monthly or quarterly payments based on this rate, directly proportional to their invested amount.
    </Tooltip>
  );
  const tooltipTwo = (
    <Tooltip id="tooltip2">
      The Target Return is an estimate of the total expected return on an investment, combining the Paid Return and the potential increase in the property's value each year.
    </Tooltip>
  );
  const tooltipPurchasePrice = (
    <Tooltip id="tooltipPurchasePrice">
      This is the total cost of acquiring a real estate Expected Capital Gain: This represents the projected change in your investment's value over the project horizon. This change is calculated based on the annual evolution of property values using the house price index and an annual estimate of the property's value.
    </Tooltip>
  );
  const tooltipRent = (
    <Tooltip id="tooltipRent">
      This represents the total income generated by monthly rents, after deduction of Crowdfina's operating costs and debt servicing.
    </Tooltip>
  );
  const tooltipExpectedCash = (
    <Tooltip id="tooltipExpectedCash">
      This is the estimated total value of your investment at the end of the property holding period, reflecting all projected income and expenses during that time.
    </Tooltip>
  );
  const tooltipMonthlyFixed = (
    <Tooltip id="tooltipMonthlyFixed">
      This is the expected net income from monthly rents, paid to you after deducting operating costs and any other fees, as managed by Crowdfina. The amount is prorated based on the value of your investment.
    </Tooltip>
  );

  return (
    <>
      {addMoney && (
        <AddMoney
          amount={rangeValue}
          show={addMoney}
          onClose={closeAddMoney}
        />
      )}
      <div
        className={
          showContent ? "dashboard-main content-visible" : "dashboard-main"
        }
      >
        {/* <div>
          <AdminSidebar />
        </div> */}
        <div className="dashboard-body">
          <DashboardHeader toggleSidebar={toggleSidebar} />
          <Container>
            <AdminNavBar />
            <div className="dashboard-content p-2 p-md-4">
              <div className="main-content p-2 p-lg-4 mb-4">
                <div className="single-property-card">
                  <Row
                  // className="align-items-center"
                  >
                    <Col lg={7} className="mb-4">
                      <div className=" mb-4">
                        {/* <img
                          src={require("../../../images/landing-banner.png")}
                          alt=""
                          className="object-fit-cover"
                        /> */}
                        <ImageGallery items={images} />
                      </div>
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div>
                          <h2 className="singlepropertyHeading">
                            {property?.name}
                          </h2>
                          <h5>
                            {property?.category?.title}
                          </h5>
                        </div>
                        <span className={`dashboard_badge d-inline-block mb-3 text-capitalize ${property?.funding_status === 'fully_funded' ? 'success' : 'warning'}`}>
                          {property?.funding_status.replaceAll("_", " ")}
                        </span>
                      </div>
                      <div className="d-flex align-items-center gap-3 pb-4 border-bottom mb-4">
                        {
                          property?.rooms > 0 ?
                            <>
                              <span>
                                <FontAwesomeIcon icon={faBed} /> {property?.rooms}
                              </span>
                              <span>|</span>
                            </> : ''
                        }
                        {
                          property?.bathrooms > 0 ?
                            <>
                              <span>
                                <FontAwesomeIcon icon={faBath} /> {property?.bathrooms}
                              </span>
                              <span>|</span>
                            </> : ""
                        }
                        {
                          property?.area > 0 ?
                            <>
                              <span>
                                <FontAwesomeIcon icon={faRuler} /> {property?.area} m²
                              </span>
                            </> : ""
                        }
                        {/* <span>|</span>
                        <span>
                          <FontAwesomeIcon icon={faHouse} /> 5
                        </span>
                        <span>|</span> */}
                      </div>
                      <h5 className="fw-bold">Financials</h5>
                      <div className="propertyDetails_tab_card mb-4">
                        {/* <h5 className="fw-bold"> Financials </h5> */}
                        <div className="propertyDetails_tab_card mb-4">
                          <Row>
                            <Col>
                              <div className="price_details w-100 border-end">
                                <p className="mb-0">€{parseFloat(property?.property_value).toLocaleString('en-US')}</p>
                                <Link>
                                  Purchase Price{" "}
                                </Link>
                              </div>
                            </Col>
                            <Col>
                              <div className="price_details w-100 border-end">
                                <p className="mb-0">{property?.yearly_return_rate}% / Year</p>
                                <Link>
                                  Paid Return{" "}
                                  <OverlayTrigger placement="bottom" overlay={tooltip}>
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                  </OverlayTrigger>
                                </Link>
                              </div>
                            </Col>
                            <Col>
                              <div className="price_details w-100 border-end">
                                <p className="mb-0">{parseFloat(property?.yearly_return_rate) + parseFloat(property?.growth_rate)}% / Year</p>
                                <Link>
                                  Target Return{" "}
                                  <OverlayTrigger placement="bottom" overlay={tooltipTwo}>
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                  </OverlayTrigger>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="propertyDetails_tab_card mb-4">
                          <Row>
                            <Col>
                              <div className="price_details w-100 border-end">
                                <p className="mb-0">€{parseFloat(property?.anticipated_monthly_rent * 12).toLocaleString('en-US')}</p>
                                <Link>
                                  Yearly Total Rent{" "}
                                </Link>
                              </div>
                            </Col>
                            <Col>
                              <div className="price_details w-100 border-end">
                                <p className="mb-0">{property?.investment_duration} Years</p>
                                <Link>
                                  Expected investment Horizon{" "}
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="propertyDetails_tab_card mb-4">
                        <h5 className="fw-bold">Property Details</h5>
                        <div className="propertyDetails_tab_card mb-4">
                          <Row>
                            <Col>
                              <div className="price_details w-100 border-end">
                                <p className="mb-0">€{parseFloat(property?.property_value).toLocaleString('en-US')}</p>
                                <Link>
                                  Purchase Price{" "}
                                  <OverlayTrigger placement="bottom" overlay={tooltipPurchasePrice}>
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                  </OverlayTrigger>
                                </Link>
                              </div>
                            </Col>
                            <Col>
                              <div className="price_details w-100 border-end">
                                <p className="mb-0">€{parseFloat(property?.anticipated_monthly_rent).toLocaleString('en-US')}</p>
                                <Link>
                                Total Rent Monthly{" "}
                                  <OverlayTrigger placement="bottom" overlay={tooltipRent}>
                                    <FontAwesomeIcon icon={faCircleInfo} />
                                  </OverlayTrigger>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="propertyDetails_tab_card mb-4" dangerouslySetInnerHTML={{ __html: property?.description }}>
                        </div>
                      </div>

                      <div className="propertyDetails_tab_card mb-4">
                        <h5 className="fw-bold">Project Calendar</h5>
                        <div className="project-calendar-map border-2 border-top">
                          <Row>
                            <Col xs={3}>
                              <div className="text-center single-map position-relative">
                                <h4 className="singleHeading">{property?.investments?.length > 0 ? moment(property?.investments[0].createdAt).format('MMMM YY') : 'Not started'}</h4>
                                <p className="singleSubheading">
                                  Fundraising start
                                </p>
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="text-center single-map position-relative">
                                <h4 className="singleHeading">{property?.investments?.length > 0 ? moment(property?.investments[property?.investments?.length - 1].createdAt).format('MMMM YY') : 'Not started'}</h4>
                                <p className="singleSubheading">Fundraising End</p>
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="text-center single-map position-relative">
                                <h4 className="singleHeading">{property?.first_revenue ? moment(property?.first_revenue).format('MMMM YY') : 'Not started'}</h4>
                                <p className="singleSubheading">First revenue</p>
                              </div>
                            </Col>
                            <Col xs={3}>
                              <div className="text-center single-map position-relative">
                                <h4 className="singleHeading">In {property?.investment_duration} Years</h4>
                                <p className="singleSubheading">End of Contract</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>


                      <div className="propertyDetails_tab_card mb-4">
                        <h5 className="fw-bold">Documents</h5>
                        <div className="propertyDetails_tab_card mb-4">
                          <div className="document-container">
                            <Row>
                              {
                                property?.contract_doc.length > 0 ? property?.contract_doc.map((ele, index) => {
                                  if (isImageFile(ele.document)) {
                                    return (<Col xs={6} key={ele?._id}>
                                      <a href={`${process.env.REACT_APP_SERVER_URL}/uploads/properties/original/${ele.document}`} target="_blank">
                                        <div className="upload-doc d-flex align-items-center justify-content-center">
                                          <FontAwesomeIcon icon={faImages} />
                                        </div>
                                      </a>
                                    </Col>)
                                  } else {
                                    return (<Col xs={6} key={ele?._id}>
                                      <a href={`${process.env.REACT_APP_SERVER_URL}/uploads/properties/original/${ele.document}`} target="_blank">
                                        <div className="upload-doc d-flex align-items-center justify-content-center">
                                          <FontAwesomeIcon icon={faFolderPlus} />
                                        </div>
                                      </a>
                                    </Col>
                                    )
                                  }
                                }) : <Col xs={12} md={12}>
                                  No document added
                                </Col>
                              }
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="document-container">
                        <Row>
                          <Col xs={6}>
                            <div className="upload-doc d-flex align-items-center justify-content-center">
                              <FontAwesomeIcon icon={faImages} />
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="upload-doc d-flex align-items-center justify-content-center">
                              <FontAwesomeIcon icon={faFolderPlus} />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={5} className="mb-4">
                      <div className="filter-card propertyDetails_tab_card">
                        <h6 className="fw-bold">Revenu Estimator: €{rangeValue > 0 ? parseFloat(rangeValue).toLocaleString('en-US') : 0} invested</h6>


                        {/* <div className="growBusiness_progress position-relative mb-3">
                          <Form.Range
                            value={rangeValue}
                            onChange={handleRangeChange}
                            min={property?.minimum_investment_amount ?? 0}
                            max={property?.property_value}
                            step={1000}
                          />
                          <span
                            className="filledProgress"
                            style={{ width: `${(100 * rangeValue) / property?.property_value}%` }}
                          ></span>
                          <span
                            className="controller"
                            style={{ left: `calc(${(100 * rangeValue) / property?.property_value}% - ${sliderValue()}px)` }}
                          ></span>
                          <span className="inputbackground"></span>
                        </div> */}

                        <Form.Control type="number" className="mb-3"
                          value={rangeValue}
                          onChange={handleRangeChange}
                          min={property?.minimum_investment_amount ?? 0}
                          max={property?.property_value}
                        />

                        <p className="mb-2">
                          Expected cash flow over Investment Horizon - {property.investment_duration} years{" "}
                          <Link className="text-muted">
                            <OverlayTrigger placement="bottom" overlay={tooltipExpectedCash}>
                              <FontAwesomeIcon icon={faCircleInfo} />
                            </OverlayTrigger>
                          </Link>
                        </p>

                        <div className="profavility_card mb-4">
                          <div className="card-header position-relative">
                            <h5 className="mb-0">+€{parseFloat(durationProfit).toLocaleString('en-US')}</h5>
                            <span className="profit-percentage">+{durationProfitAvg.toLocaleString('en-US')}%</span>
                          </div>

                          <div className="p-3">
                            <p className="text-center fw-semibold text-muted mb-0">
                              €{parseFloat(durationProfit).toLocaleString('en-US')} donated + €0 bonus
                            </p>
                          </div>
                        </div>
                        <p>
                          Monthly Fixed profitability{" "}
                          <Link className="text-muted">
                            <OverlayTrigger placement="bottom" overlay={tooltipMonthlyFixed}>
                              <FontAwesomeIcon icon={faCircleInfo} />
                            </OverlayTrigger>
                          </Link>
                        </p>
                        <div className="profavility_card mb-4">
                          <div className="card-header position-relative">
                            <h5 className="mb-0">+€{parseFloat(monthlyProfit).toLocaleString('en-US')}</h5>
                            <span className="profit-percentage">+{monthlyProfitAvg.toFixed(2)}%</span>
                          </div>
                        </div>
                        <p>
                          Expected Capital Gain{" "}
                          <Link className="text-muted">
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </Link>
                        </p>
                        <div className="profavility_card mb-4">
                          <div className="card-header position-relative">
                            <h5 className="mb-0">+€{calculateExpectedGain(rangeValue, property?.growth_rate, property?.investment_duration).toLocaleString('en-US')}</h5>
                          </div>
                        </div>
                        {
                          property?.totalInvested >= property?.property_value
                            ? <Button className="w-100 text-capitalize">Fully Funded</Button>
                            : (
                              parseInt(walletBalance) >= rangeValue
                                ? (
                                  property?.funding_status === 'funding_in_progress'
                                    ? <Button className="w-100" onClick={investMoney}>Invest Money</Button>
                                    : <Button className="w-100 text-capitalize">{property?.funding_status.replaceAll("_", " ")}</Button>
                                )
                                :
                                isLoading ? <Loader /> : <Button className="w-100" onClick={() => setAddMoney(true)}>Add Money To Wallet</Button>
                            )
                        }
                      </div>

                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Container>
        </div >
      </div >
    </>
  );
}

export default PropertyDetail;
