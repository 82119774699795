import React from 'react'

export default function PageNotFound() {
    return (
        <div>
            <section class="page_404">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 ">
                            <div class="col-sm-10 col-sm-offset-1  text-center">
                                <div class="four_zero_four_bg">
                                    <h2 class="text-center ">404</h2>
                                </div>

                                <div class="contant_box_404">
                                    <h3 class="h2">Looks like page not found</h3>
                                    {/* <p>Wait for new surprise.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}