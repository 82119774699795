import React, { useState } from "react";
import { useFormik } from "formik";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import { addCardSchema } from "../../../utils/validationsSchema";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { addCardThunk } from "../../../features/card/cardThunk";
import { authSelector } from "../../../features/auth/authSelector";
import { loadStripe } from '@stripe/stripe-js';
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import Loader from "../../../Loader";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


export default function AddCard(props) {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const { userData, token } = useSelector(authSelector);
    const [paymentError, setPaymentError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [configObject, setConfigObject] = useState({
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    const formik = useFormik({
        initialValues: {
            holder_name: "",
        },
        validationSchema: addCardSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                if (userData?.stripe_customer_id && userData?.stripe_account_id) {

                    setLoading(true);
                    if (!stripe || !elements) {
                        return;
                    }

                    const { error, paymentMethod } = await stripe.createPaymentMethod({
                        type: "card",
                        card: elements.getElement(
                            CardNumberElement,
                            CardExpiryElement,
                            CardCvcElement
                        )
                    });

                    if (error) {
                        setPaymentError(error.message);
                    } else {
                        setPaymentError(null);
                        let payload = {
                            holder_name: values.holder_name,
                            card_no: paymentMethod.card.last4,
                            exp_date: paymentMethod.card.exp_month + '/' + paymentMethod.card.exp_year,
                            paymentMethodId: paymentMethod.id,
                            brand: paymentMethod.card.brand,
                        }

                        const response = await dispatch(addCardThunk({ ...configObject, body: payload }));

                        if (response?.payload?.status === 200) {
                            toast.success('Card saved successfully');
                            props.onClose();
                            resetForm();
                        } else {
                            toast.error("Card not saved");
                        }
                    }
                    setLoading(false);
                } else {
                    toast.error("Please add your documents details.");
                }

            } catch (error) {
                toast.error("Something went wrong.");
            }
        },
    });

    const cardElementOptions = {
        style: {
            base: {
                fontSize: "16px",
                color: "#000", // Text color
                "::placeholder": {
                    color: "#aab7c4", // Placeholder text color
                },
                background: "#F2F2F2",
            },
            invalid: {
                color: "#dc3545", // Invalid text color
            },
        },
        classes: {
            base: 'form-control',
            complete: 'stripe-element-complete',
            empty: 'stripe-element-empty',
            focus: 'stripe-element-focus',
            invalid: 'stripe-element-invalid',
            webkitAutofill: 'stripe-element-webkit-autofill',
        },
    };

    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Card</Modal.Title>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Card Holder Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            name="holder_name"
                            value={formik.values.holder_name}
                            onChange={formik.handleChange}
                        />
                        <span style={{ color: "red" }}>
                            {formik.errors.holder_name ? (
                                <div>{formik.errors.holder_name} </div>
                            ) : null}{" "}
                        </span>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Card Number</Form.Label>
                        <CardNumberElement options={cardElementOptions} />
                        {/* <Form.Control
                            type="number"
                            placeholder="Enter card number"
                            name="card_no"
                            value={formik.values.card_no}
                            onChange={formik.handleChange}
                        /> */}
                        <span style={{ color: "red" }}>
                            {formik.errors.card_no ? (
                                <div>{formik.errors.card_no} </div>
                            ) : null}{" "}
                        </span>
                    </Form.Group>

                    <Row>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput2"
                            >
                                <Form.Label>Expire Date</Form.Label>
                                <CardExpiryElement options={cardElementOptions} />
                                {/* <Form.Control
                                    type="text"
                                    placeholder="MM/YYYY"
                                    name="exp_date"
                                    value={formik.values.exp_date}
                                    onChange={formik.handleChange}
                                /> */}
                                <span style={{ color: "red" }}>
                                    {formik.errors.exp_date ? (
                                        <div>{formik.errors.exp_date} </div>
                                    ) : null}{" "}
                                </span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput2"
                            >
                                <Form.Label>CVV</Form.Label>
                                <CardCvcElement options={cardElementOptions} />
                                {/* <Form.Control
                                    type="number"
                                    placeholder="CVV"
                                    name="cvv"
                                    value={formik.values.cvv}
                                    onChange={formik.handleChange}
                                /> */}
                                <span style={{ color: "red" }}>
                                    {formik.errors.cvv ? (
                                        <div>{formik.errors.cvv} </div>
                                    ) : null}{" "}
                                </span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <span style={{ color: "red" }}>
                        {paymentError != null ? <div>{paymentError}</div> : null}
                    </span>

                </Modal.Body>
                <Modal.Footer>
                    {
                        isLoading ? <Loader /> : <Button variant="primary" type="submit" className="w-100">
                            Add Card
                        </Button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
