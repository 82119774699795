import React from 'react'

export default function Loader() {
  return (
    <div className="d-flex justify-content-center loader">
      <div className="spinner-border spinner-border-lg" style={{ width: '4rem', height: '4rem', color: '#BEBEBE' }} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}