import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import { addmoneySchema } from "../../../utils/validationsSchema";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { getCardsThunk } from "../../../features/card/cardThunk";
import { cardSelector } from "../../../features/card/cardSelector";
import { authSelector } from "../../../features/auth/authSelector";
import Slider from "react-slick";
import { addTransactionThunk, getTransactionStatsThunk, addPaymentThunk } from "../../../features/transaction/transactionThunk";
import Loader from "../../../Loader";
import { getProfileThunk } from "../../../features/auth/authThunk";

export default function AddMoney(props) {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [cardError, setCardError] = useState("");
    const [amount, setAmount] = useState(props?.amount ? props?.amount : 1000);
    const { userData, token } = useSelector(authSelector);
    const { cards, total, limit, page } = useSelector(cardSelector);
    const [configObject, setConfigObject] = useState({
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    var PaymentsCards = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const maskCardNumber = (cardNumber) => {
        // Assuming the card number has 16 digits
        const visibleDigits = 4;
        const maskedNumber = '*'.repeat(16 - visibleDigits) + cardNumber.slice(-visibleDigits);
        return maskedNumber;
    };

    useEffect(() => {
        getCards();
    }, []);

    const getCards = async () => {
        const resp = await dispatch(getCardsThunk({ ...configObject }));
        if (resp?.payload?.status == 200 && resp?.payload?.data?.body?.count == 0) {
            setCardError('Please add card first to add money');
        }
    }

    const formik = useFormik({
        initialValues: {
            card_no: "",
            amount: ""
        },
        validationSchema: addmoneySchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setLoading(true);
                const response = await dispatch(addPaymentThunk({
                    ...configObject, body: {
                        type: 'deposit',
                        via: 'payment',
                        status: 'completed',
                        amount: values.amount,
                        card_no: values.card_no
                    }
                }));

                if (response?.payload?.status === 200) {
                    toast.success('Money added successfully');
                    await dispatch(getTransactionStatsThunk({ ...configObject }));
                    await dispatch(getProfileThunk({ ...configObject }));
                    props.onClose();
                } else {
                    toast.error(response?.payload?.response?.data?.message ?? 'Some problem exist. Please try again.');
                }
                setLoading(false);
            } catch (error) {

                toast.error("Something went wrong.");
            }
        },
    });

    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Select Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Slider {...PaymentsCards} className="mb-4">
                    <div className="px-2">
                        <img
                            src={require("../../../images/payment_card2.png")}
                            alt=""
                            className="w-100"
                        />
                    </div>
                    <div className="px-2">
                        <img
                            src={require("../../../images/payment_card2.png")}
                            alt=""
                            className="w-100"
                        />
                    </div>
                    <div className="px-2">
                        <img
                            src={require("../../../images/payment_card3.png")}
                            alt=""
                            className="w-100"
                        />
                    </div>
                </Slider>
                <Form className="payment_method_form">
                    <Form.Group className="mb-3" controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            type="number"
                            name="amount"
                            min="1"
                            value={formik.values.amount}
                            placeholder="Enter amount"
                            onChange={formik.handleChange}
                        />
                        <span style={{ color: "red" }}>
                            {formik.errors.amount ? (
                                <div>{formik.errors.amount} </div>
                            ) : null}{" "}
                        </span>
                    </Form.Group>
                    {
                        total > 0 && cards.map((item) => {
                            return (<Form.Group className="mb-3" controlId={item?._id} key={item?._id}>
                                <Form.Control
                                    type="radio"
                                    name="card_no"
                                    checked={formik.values.card_no === item?.stripe_card_id}
                                    value={item?.stripe_card_id}
                                    onChange={() => formik.setFieldValue("card_no", item?.stripe_card_id)}
                                />
                                <Form.Label>
                                    <Row className="align-items-center h-100">
                                        <Col md={4}>
                                            {item?.brand}
                                            {/* <img src={require("../../../images/visacard.png")} alt="" /> */}
                                        </Col>
                                        <Col md={6}>
                                            <span>{maskCardNumber(item?.card_no)}</span>
                                        </Col>
                                    </Row>
                                </Form.Label>
                                <span style={{ color: "red" }}>
                                    {formik.errors.card_no ? (
                                        <div>{formik.errors.card_no} </div>
                                    ) : null}{" "}
                                </span>
                            </Form.Group>)
                        })
                    }

                    <span style={{ color: "red" }}>
                        <div>{cardError} </div>
                    </span>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {
                    isLoading ? <Loader /> :
                        <Button variant="primary" className="w-100" onClick={formik.handleSubmit} disabled={cardError ? true : false}>
                            Add Money
                        </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}
