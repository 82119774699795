import React, { useState, useEffect, useMemo } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Offcanvas,
  ProgressBar,
  Row,
  Tab,
  Tabs,
  Dropdown
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin, faCircleInfo, } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import AdminNavBar from "../AdminNavBar";
import { getPropertiesThunk } from "../../../features/property/propertyThunk";
import { getCategoriesThunk } from "../../../features/category/categoryThunk";
import { categorySelector } from "../../../features/category/categorySelector";
import { propertySelector } from "../../../features/property/propertySelector";
import {
  changeLimit,
  changePage,
} from "../../../features/property/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import ReactPaginate from "react-paginate";

function SingleInvest() {
  const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleSidebar = () => setShowContent(!showContent);
  const { categories } = useSelector(categorySelector);
  const [selectedTab, setSelectedTab] = useState("");
  const { properties, total, limit, page } = useSelector(propertySelector);
  const { token, userData } = useSelector(authSelector);

  const [configObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      limit: limit,
      page: page,
    },
  });

  const pageCount = useMemo(() => {
    const pageCounter = Math.ceil(total / limit);
    return pageCounter;
  }, [total, limit]);

  useEffect(() => {
    getProperties();
    getCategories();
  }, []);

  const getCategories = async () => {
    await dispatch(getCategoriesThunk({ ...configObject }));
  }

  const getProperties = async () => {
    await dispatch(getPropertiesThunk({ ...configObject }));
  };

  const handlePageClick = async (data) => {
    await dispatch(changePage(data.selected + 1));
    await dispatch(
      getPropertiesThunk({
        ...configObject,
        params: {
          limit: limit,
          page: data.selected + 1,
        },
      })
    );
  };

  const propertyDetailPage = (property) => {
    if (token) {
      navigate('/propertydetails', { state: { property: property } })
    } else {
      navigate('/login')
    }
  }

  const handleTabSelect = async (selected) => {
    setSelectedTab(selected)
    await dispatch(getPropertiesThunk({
      ...configObject, params: {
        category: selected,
        limit: limit,
        page: 1,
      }
    }));
  };

  const inverstorImages = (totalcount) => {
    let number = totalcount > 3 ? 3 : totalcount;
    const numbers = Array.from({ length: number }, (_, index) => index);
    return numbers;
  }

  return (
    <>
      <div
        className={
          showContent ? "dashboard-main content-visible" : "dashboard-main"
        }
      >
        {/* <div>
          <AdminSidebar />
        </div> */}
        <div className="dashboard-body">
          <DashboardHeader toggleSidebar={toggleSidebar} />

          <Container>
            {/* <AdminNavBar /> */}
            <div className="top-navlinks">
              <Tabs
                activeKey={selectedTab}
                //defaultActiveKey=""
                id="uncontrolled-tab-example"
                className="navbar_list mb-3 border-0 gap-3"
                onSelect={handleTabSelect}
              >
                <Tab eventKey="" title="All">
                  <Row>
                    {
                      total > 0 && properties.map((item) => {

                        return (
                          <Col
                            key={item?._id}
                            md={6} lg={3}
                            className="mb-4"
                            onClick={() => propertyDetailPage(item)}
                          >
                            <div className="investPropertycard p-3 h-100 position-relative">
                              <span className={`dashboard_badge progress-pill text-capitalize ${item?.funding_status === 'fully_funded' ? 'success' : 'warning'}`}>
                                {item?.funding_status.replaceAll("_", " ")}
                              </span>
                              {/* <span className={`dashboard_badge progress-pill right-10 primary p-2 z-10`}>
                                <FontAwesomeIcon icon={faCircleInfo} />
                              </span> */}
                              <div className="property-img-container position-relative mb-4">
                                {item?.property_images?.length > 0 ? (
                                  <img
                                    src={`${process.env.REACT_APP_SERVER_URL}/uploads/properties/original/${item?.property_images[0]?.image}`}
                                    alt=""
                                    className="property-img img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={require("../../../images/authbanner.png")}
                                    alt=""
                                    className="property-img img-fluid"
                                  />
                                )}

                                <div className="overlay-text">
                                  <h4 className="propertyHeading">{item?.name}</h4>
                                  <h6 className="">{item?.category?.title}</h6>
                                  <p className="address mb-2">
                                    <span className="me-2">
                                      <FontAwesomeIcon icon={faLocationPin} />
                                    </span>
                                    {item?.city},{" "}{item?.country}
                                  </p>
                                </div>
                              </div>
                              <div className="invest-details">
                                <h5 className="d-flex align-items-center justify-content-between"><b>Paid Return</b> <span>{item?.yearly_return_rate}% / Year</span></h5>
                                <h5 className="d-flex align-items-center justify-content-between"><b>Target Return</b> <span>{parseFloat(item?.yearly_return_rate) + parseFloat(item?.growth_rate)}% / Year</span></h5>
                                <p> €{parseFloat(item?.property_value).toLocaleString('en-US')} | {item?.investment_duration} Years</p>
                              </div>
                              {/* <p className="expected_rate mb-0">
                                Property Value &nbsp;
                                <span className="rate">
                                  ${item?.property_value}
                                </span>
                              </p> */}
                              {
                                item?.funding_status != 'funding_starting_soon' &&
                                <>
                                  {
                                    item?.funding_status == 'fully_funded'
                                      ? <div className="list_stylle py-1 px-3 text-center bg-transparent shadow-none">
                                        <div className="authors_style">
                                          {
                                            (item?.totalInvestors > 0 && item?.totalInvestors <= 3) && inverstorImages(item?.totalInvestors).map((i) => {
                                              return (<span key={i}>
                                                <img src={require('../../../images/dummy.jpg')}
                                                  className={i != 0 && 'img_margin'}
                                                  alt="group" />
                                              </span>)
                                            })
                                          }
                                        </div>
                                        <h5 className="my-1">
                                          €{item?.totalInvested ?? 0}
                                        </h5>
                                        <p className="mb-0">Funded by {item?.totalInvestors ?? 0} Investors</p>
                                      </div>
                                      : <>
                                        <div className="d-flex align-items-center flex-wrap">
                                          <div>
                                            <div className="authors_style">
                                              {
                                                item?.totalInvestors > 0 && inverstorImages(item?.totalInvestors).map((i) => {
                                                  return (<span key={i}>
                                                    <img src={require('../../../images/dummy.jpg')}
                                                      className={i != 0 ? 'img_margin' : ''}
                                                      alt="group" />
                                                  </span>)
                                                })
                                              }
                                            </div>
                                          </div>
                                          <div>
                                            <div>
                                              <p className="mb-0">
                                                <b>{item?.totalInvestors ?? 0}</b> investors
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <Row className="align-items-center">
                                          <Col xs={7}>
                                            <h6 className="fw-semibold">€{item?.totalInvested ?? 0}</h6>
                                            <div className="progressBar-container mb-3">
                                              <ProgressBar now={(item?.totalInvested / item?.property_value) * 100} />
                                            </div>
                                          </Col>
                                          <Col xs={12}>
                                            <div className="text-end">
                                              <button className="btn btn-primary w-100 px-3">Buy</button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </>
                                  }
                                </>
                              }
                            </div>
                          </Col>
                        )
                      })
                    }
                  </Row>
                </Tab>
                {
                  categories.length > 0 && categories.map((category) => {
                    return (
                      <Tab eventKey={category?._id} title={category?.title} key={category?._id} >
                        <Row>
                          {
                            total > 0 && properties.map((item) => {
                              return (

                                <Col
                                  key={item?._id}
                                  md={6} lg={3}
                                  className="mb-4"
                                  onClick={() =>
                                    navigate("/propertydetails", {
                                      state: { property: item },
                                    })
                                  }
                                >
                                  <div className="investPropertycard p-3 h-100 position-relative">
                                    <span className={`dashboard_badge progress-pill text-capitalize ${item?.funding_status === 'fully_funded' ? 'success' : 'warning'}`}>
                                      {item?.funding_status.replaceAll("_", " ")}
                                    </span>
                                    <div className="property-img-container position-relative mb-4">
                                      {item?.property_images?.length > 0 ? (
                                        <img
                                          src={`${process.env.REACT_APP_SERVER_URL}/uploads/properties/original/${item?.property_images[0]?.image}`}
                                          alt=""
                                          className="property-img img-fluid "
                                        />
                                      ) : (
                                        <img
                                          src={require("../../../images/authbanner.png")}
                                          alt=""
                                          className="property-img img-fluid "
                                        />
                                      )}
                                      <div className="overlay-text">
                                        <h4 className="propertyHeading">{item?.name}</h4>
                                        <h6 className="">{item?.category?.title}</h6>
                                        <p className="address mb-2">
                                          <span className="me-2">
                                            <FontAwesomeIcon icon={faLocationPin} />
                                          </span>
                                          {item?.city},{" "}{item?.country}
                                        </p>
                                      </div>
                                    </div>
                                    {/* <p className="expected_rate mb-0">
                                      Property Value &nbsp;
                                      <span className="rate">
                                        ${item?.property_value}
                                      </span>
                                    </p> */}
                                    <div className="invest-details">
                                      <h5 className="d-flex align-items-center justify-content-between"><b>Paid Return</b> <span>{item?.yearly_return_rate}%/ Year</span></h5>
                                      <h5 className="d-flex align-items-center justify-content-between"><b>Target Return</b> <span>{parseFloat(item?.yearly_return_rate) + parseFloat(item?.growth_rate)}%</span></h5>
                                      <p> €{parseFloat(item?.property_value).toLocaleString('en-US')} | {item?.investment_duration} Years</p>
                                    </div>

                                    {
                                      item?.funding_status != 'funding_starting_soon' &&
                                      <>
                                        {
                                          item?.funding_status == 'fully_funded'
                                            ? <div className="list_stylle py-1 px-3 text-center bg-transparent shadow-none">
                                              <div className="authors_style">
                                                {
                                                  item?.totalInvestors > 0 && inverstorImages(item?.totalInvestors).map((i) => {
                                                    return (<span key={i}>
                                                      <img src={require('../../../images/dummy.jpg')}
                                                        className={i != 0 && 'img_margin'}
                                                        alt="group" />
                                                    </span>)
                                                  })
                                                }
                                              </div>
                                              <h5 className="my-1">
                                                €{item?.totalInvested ?? 0}
                                              </h5>
                                              <p className="mb-0">Funded by {item?.totalInvestors ?? 0} Investors</p>
                                            </div>
                                            : <>
                                              <div className="d-flex align-items-center">
                                                <div>
                                                  <div className="authors_style">
                                                    {
                                                      item?.totalInvestors > 0 && inverstorImages(item?.totalInvestors).map((i) => {
                                                        return (<span key={i}>
                                                          <img src={require('../../../images/dummy.jpg')}
                                                            className={i != 0 ? 'img_margin' : ''}
                                                            alt="group" />
                                                        </span>)
                                                      })
                                                    }

                                                  </div>
                                                </div>
                                                <div xs={8}>
                                                  <div>
                                                    <p className="mb-0">
                                                      <b>{item?.totalInvestors ?? 0}</b> investors
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <Row className="align-items-center">
                                                <Col xs={7}>
                                                  <h6 className="fw-semibold">€{item?.totalInvested ?? 0}</h6>
                                                  <div className="progressBar-container">
                                                    <ProgressBar now={(item?.totalInvested / item?.property_value) * 100} />
                                                  </div>
                                                </Col>
                                                <Col xs={5}>
                                                  <div className="text-end">
                                                    <button className="btn btn-primary px-3">Buy</button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </>
                                        }


                                      </>
                                    }

                                  </div>
                                </Col>)
                            })
                          }
                        </Row>

                      </Tab>
                    )
                  })
                }
              </Tabs>

              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount === 1 ? 1 : pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"pagination_prev"}
                previousLinkClassName={"page-link"}
                nextClassName={"pagination_next"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SingleInvest;
