import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
const api_url = process.env.REACT_APP_API_URL_DEV;

export const getCardsThunk = createAsyncThunk(
    'cards/get',
    async ({ headers, params }) => {
        const config = {
            headers: { ...headers },
            params: params
        }
        try {
            const data = await axios.get(`${api_url}/cards/list`, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const addCardThunk = createAsyncThunk(
    'cards/add',
    async ({ body, headers }) => {
        const config = {
            headers: {
                Authorization: headers.Authorization,
            },
        };
        try {
            const data = await axios.post(`${api_url}/cards/create`, body, config);
            return data;
        } catch (error) {
            toast.error(error?.response?.data?.message)
        }
    }
)