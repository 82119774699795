import React, { useState, useEffect } from "react";
import Header from "../common/header";
import Footer from "../common/footer";
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Offcanvas,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { getPropertiesThunk } from "../../features/property/propertyThunk";
import { propertySelector } from "../../features/property/propertySelector";
import { changeLimit, changePage } from "../../features/property/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../features/auth/authSelector";
import { getCategoriesThunk } from "../../features/category/categoryThunk";
import { categorySelector } from "../../features/category/categorySelector";
import { useFormik } from "formik";
import ReactFlagsSelect from "react-flags-select";

import { Link, useNavigate } from "react-router-dom"


function Invest() {
  const [show, setShow] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [comingsoon, setComingsoon] = useState(false);
  const [rangeValue, setRangeValue] = useState(50); // Initial value is set to 50, you can set it to your preferred initial value

  // Event handler for the range change
  const handleRangeChange = (e) => {
    setRangeValue(parseInt(e.target.value, 10));
    console.log("e.target.value", e.target.value);
  };
  // var apartmentSettings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  const { properties, total, limit, page } = useSelector(propertySelector);
  const { token, userData } = useSelector(authSelector);
  const { categories } = useSelector(categorySelector);

  const [configObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      limit: limit,
      page: page,
    },
  });

  useEffect(() => {
    getProperties();
    getCategories();
  }, []);

  const getProperties = async () => {
    await dispatch(getPropertiesThunk({ ...configObject }));
  }

  const getCategories = async () => {
    await dispatch(getCategoriesThunk({ ...configObject }));
  }

  const handleTabSelect = async (selected) => {
    setSelectedTab(selected)
    await dispatch(getPropertiesThunk({
      ...configObject, params: {
        category: selected
      }
    }));
  };

  const formik = useFormik({
    initialValues: {
      category: selectedTab,
      funding_status: "",
      country: "",
      rent_status: ""
    },
    onSubmit: async (values) => {
      setSelectedTab(values.category);
      await dispatch(getPropertiesThunk({
        ...configObject, params: {
          ...values
        }
      }));
    }
  });


  const propertyDetailPage = (property) => {
    if (token) {
      navigate('/propertydetails', { state: { property: property } })
    } else {
      navigate('/login')
    }
  }

  const inverstorImages = (totalcount) => {
    let number = totalcount > 3 ? 3 : totalcount;
    const numbers = Array.from({ length: number }, (_, index) => index);
    return numbers;
  }


  return (
    <>
      <Header />
      {/* ====== common banner ======= */}
      {/* <div className="static_banner position-relative">
        <Container>
          <div className="text-center">
            <h3>Invest</h3>
            <p>
              With an unwavering commitment to excellence, CrowdFina is on an{" "}
              <br /> odyssey to revolutionize the landscape of real estate.
            </p>
          </div>
          <Breadcrumb className="pages-breadcrumbs">
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Invest</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
        <img src={require("../../images/banner-wave.png")} alt="wave" className="static-banner-shape"  />
      </div> */}
      {
        !comingsoon ? <div className="dashboard-main common-padding apartment-section">
          <Container>
            {/* <p className="common-badge">Buildings</p> */}
            <Row>
              <Col lg={8}>
                <div className="common-heading-section mb-5">
                  <h2>Properties list</h2>
                  <p className="pb-4">
                    It is a long established fact that a reader will be distracted
                    by the readable content of a page when looking at its layout.
                  </p>
                </div>
              </Col>
            </Row>
            {/* <Slider {...apartmentSettings}> */}
            <div className="position-relative">
              <div className="filter-btn" onClick={handleShow}>
                <span className="me-2">
                  <FontAwesomeIcon icon={faFilter} />
                </span>
                Filter
              </div>

              <Tabs
                activeKey={selectedTab}
                //defaultActiveKey=""
                id="uncontrolled-tab-example"
                className="invest-tabs mb-3 border-0"
                onSelect={handleTabSelect}
              >
                <Tab eventKey="" title="All">
                  <Row>
                    {
                      total > 0 && properties.map((item) => {

                        return (<Col lg={4} key={item?._id} onClick={() => propertyDetailPage(item)}>

                          <div className="investPropertycard p-3 h-100 position-relative">
                            <div className="property-img-container position-relative mb-4">
                              <span className={`dashboard_badge progress-pill text-capitalize ${item?.funding_status === 'fully_funded' ? 'success' : 'warning'}`}>
                                {item?.funding_status.replaceAll("_", " ")}
                              </span>
                              <span className={`dashboard_badge progress-pill right-10 primary p-2 z-10`}>
                                <FontAwesomeIcon icon={faCircleInfo} />
                              </span>
                              {
                                item?.property_images?.length > 0 ? <img
                                  src={`${process.env.REACT_APP_SERVER_URL}/uploads/properties/original/${item?.property_images[0]?.image}`}
                                  alt="" className="property-img img-fluid"
                                />
                                  : <img
                                    src={require("../../images/authbanner.png")}
                                    alt="" className="property-img img-fluid"
                                  />
                              }

                              <div className="overlay-text">
                                <h4 className="propertyHeading">
                                  {item?.name}
                                </h4>
                                <p className="address mb-2">
                                  {item?.category?.title}
                                </p>
                              </div>
                            </div>

                            <div class="invest-details">
                              <h5 className="d-flex align-items-center justify-content-between"><b>Return</b><span>{item?.yearly_return_rate}%/ Year</span></h5>
                              <h5 className="d-flex align-items-center justify-content-between"><b>Target Return</b><span>{parseFloat(item?.yearly_return_rate) + parseFloat(item?.growth_rate)}%</span></h5>
                              <p>  €{parseFloat(item?.property_value).toLocaleString('en-US')} | {item?.investment_duration}</p>
                            </div>
                            {
                              item?.funding_status != 'funding_starting_soon' &&
                              <>
                                {
                                  item?.funding_status == 'fully_funded'
                                    ? <div className="list_stylle py-1 px-3 text-center bg-transparent shadow-none">
                                      <div className="authors_style">
                                        {
                                          item?.totalInvestors > 0 && inverstorImages(item?.totalInvestors).map((i) => {
                                            return (<span key={i}>
                                              <img src={require('../../images/dummy.jpg')}
                                                className={i != 0 && 'img_margin'}
                                                alt="group" />
                                            </span>)
                                          })
                                        }
                                      </div>
                                      <h5 className="my-1">
                                        €{item?.totalInvested ?? 0}
                                      </h5>
                                      <p className="mb-0">Funded by {item?.totalInvestors ?? 0} Investors</p>
                                    </div>
                                    : <>
                                      <div className="d-flex align-items-center">
                                        <div>
                                          <div className="authors_style">
                                            {
                                              item?.totalInvestors > 0 && inverstorImages(item?.totalInvestors).map((i) => {
                                                return (<span key={i}>
                                                  <img src={require('../../images/dummy.jpg')}
                                                    className={i != 0 ? 'img_margin' : ''}
                                                    alt="group" />
                                                </span>)
                                              })
                                            }

                                          </div>
                                        </div>
                                        <div>
                                          <div>
                                            <p className="mb-0">
                                              <b>{item?.totalInvestors ?? 0}</b> investors
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <Row className="align-items-center">
                                        <Col xs={7}>
                                          <h6 className="fw-semibold">€{item?.totalInvested ?? 0}</h6>
                                          <div className="progressBar-container mb-3">
                                            <ProgressBar now={(item?.totalInvested / item?.property_value) * 100} />
                                          </div>
                                        </Col>
                                        <Col xs={12}>
                                          <div className="text-end">
                                            <button className="btn btn-primary px-3 w-100">Buy</button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </>
                                }


                              </>
                            }
                          </div>
                        </Col>)
                      })
                    }
                  </Row>
                </Tab>
                {
                  categories.length > 0 && categories.map((category) => {
                    return (
                      <Tab eventKey={category?._id} title={category?.title} key={category?._id}>
                        <Row>
                          {
                            total > 0 && properties.map((item) => {
                              return (

                                <Col lg={4} key={item?._id} onClick={() => propertyDetailPage(item)}>

                                  <div className="investPropertycard p-3 h-100 position-relative">

                                    <span className={`dashboard_badge progress-pill text-capitalize ${item?.funding_status === 'fully_funded' ? 'success' : 'warning'}`}>
                                      {item?.funding_status.replaceAll("_", " ")}
                                    </span>
                                    <span className={`dashboard_badge progress-pill right-10 primary p-2 z-10`}>
                                      <FontAwesomeIcon icon={faCircleInfo} />
                                    </span>
                                    <div className="property-img-container position-relative mb-4">

                                      {
                                        item?.property_images?.length > 0 ? <img
                                          src={`${process.env.REACT_APP_SERVER_URL}/uploads/properties/original/${item?.property_images[0]?.image}`}
                                          alt="" className="property-img img-fluid"
                                        />
                                          : <img
                                            src={require("../../images/authbanner.png")}
                                            alt="" className="property-img img-fluid"
                                          />
                                      }

                                      <div className="overlay-text">
                                        <h4 className="propertyHeading">
                                          {item?.name}
                                        </h4>
                                        <p className="address mb-2">
                                          {item?.category?.title}
                                        </p>
                                      </div>
                                    </div>

                                    <div class="invest-details">
                                      <h5 className="d-flex align-items-center justify-content-between"><b>Paid Return</b> <span>{item?.yearly_return_rate}% / Year</span></h5>
                                      <h5 className="d-flex align-items-center justify-content-between"><b>Target Return</b> <span>{parseFloat(item?.yearly_return_rate) + parseFloat(item?.growth_rate)}% / Year</span></h5>
                                      <p> €{parseFloat(item?.property_value).toLocaleString('en-US')} | {item?.investment_duration} Years</p>
                                    </div>
                                    {
                                      item?.funding_status != 'funding_starting_soon' &&
                                      <>
                                        {
                                          item?.funding_status == 'fully_funded'
                                            ? <div className="list_stylle py-1 px-3 text-center bg-transparent shadow-none">
                                              <div className="authors_style">
                                                {
                                                  item?.totalInvestors > 0 && inverstorImages(item?.totalInvestors).map((i) => {
                                                    return (<span key={i}>
                                                      <img src={require('../../images/dummy.jpg')}
                                                        className={i != 0 && 'img_margin'}
                                                        alt="group" />
                                                    </span>)
                                                  })
                                                }
                                              </div>
                                              <h5 className="my-1">
                                                €{item?.totalInvested ?? 0}
                                              </h5>
                                              <p className="mb-0">Funded by {item?.totalInvestors ?? 0} Investors</p>
                                            </div>
                                            : <>
                                              <div className="d-flex align-items-center">
                                                <div xs={12}>
                                                  <div className="authors_style">
                                                    {
                                                      item?.totalInvestors > 0 && inverstorImages(item?.totalInvestors).map((i) => {
                                                        return (<span key={i}>
                                                          <img src={require('../../images/dummy.jpg')}
                                                            className={i != 0 ? 'img_margin' : ''}
                                                            alt="group" />
                                                        </span>)
                                                      })
                                                    }
                                                  </div>
                                                </div>
                                                <div xs={12}>
                                                  <div>
                                                    <p className="mb-0">
                                                      <b>{item?.totalInvestors ?? 0}</b> investors
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <Row className="align-items-center">
                                                <Col xs={7}>
                                                  <h6 className="fw-semibold">€{item?.totalInvested ?? 0}</h6>
                                                  <div className="progressBar-container mb-3">
                                                    <ProgressBar now={(item?.totalInvested / item?.property_value) * 100} />
                                                  </div>
                                                </Col>
                                                <Col xs={12}>
                                                  <div className="text-end">
                                                    <button className="btn btn-primary w-100 px-3">Buy</button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </>
                                        }
                                      </>
                                    }
                                  </div>


                                </Col>)
                            })
                          }
                        </Row>
                      </Tab>
                    )


                  })
                }
              </Tabs>
            </div>

            {/* </Slider> */}
          </Container>
        </div> :
          <>
            <div className="static_banner coming-soon-banner position-relative">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={12}>
                    <div className="text-center mx-auto">
                      <img
                        src={require("../../images/comming_soon.png")}
                        alt="coming soon"
                        className="w-100 coming-soon-img"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
              <img src={require("../../images/banner-wave.png")} alt="wave" className="static-banner-shape" />
            </div></>

      }



      {/* <div className="video-cta py-5">
        <Container>
          <div className="position-relative blue-bg">
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="video-img mb-3">
                  <img
                    src={require("../../images/video-cta-img.png")}
                    alt="image"
                  />
                </div>
              </Col>
              <Col lg={5}>
                <div className="common-heading-section">
                  <h2 className="text-white">Learning Center</h2>
                  <p className="text-white">
                    How to Start Investing with CrowdFina
                  </p>
                  <small>
                    Venessa has captured some of the most photojournalistic
                    images with nearly every step, my microspikes slipped off
                    the soles of my chukka boots.
                  </small>
                </div>
              </Col>
            </Row>
            <div className="shape-blue position-absolute d-none d-md-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="135"
                height="205"
                viewBox="0 0 135 205"
                fill="none"
              >
                <rect
                  x="23.3379"
                  y="70.3408"
                  width="113.335"
                  height="113.335"
                  rx="19"
                  transform="rotate(11.5097 23.3379 70.3408)"
                  fill="#55BCC9"
                />
                <rect
                  x="67.9844"
                  y="0.731445"
                  width="56.6673"
                  height="56.6673"
                  rx="15"
                  transform="rotate(11.5097 67.9844 0.731445)"
                  fill="#55BCC9"
                />
              </svg>
            </div>
          </div>
        </Container>
      </div> */}
      {/* <div className="common-padding choooseUs-section">
        <Container>
          <Row>
            <Col lg={6}>
              <p className="common-badge">Why Choose us</p>

              <div className="common-heading-section">
                <h2 className="">We Help You to GrowYour Business Quickly</h2>
                <p className="">
                  Leverage agile frameworks to provide a robust synopsis for
                  high level overviews. Iterative approaches to corporate
                  strategy foster collaborative thinking to further the overall
                  value proposition. Organically grow the holistic world view of
                  disruptive innovation via workplace diversity and empowerment.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <Card className="border-0 choooseUs-progress-card">
                <CardBody>
                  <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                      <p className="progress-heading">Success Rate</p>
                      <span className="valueSpan">{rangeValue}</span>
                    </div>
                    <div className="growBusiness_progress position-relative">
                      <Form.Range
                        value={rangeValue}
                        onChange={handleRangeChange}
                        min={0}
                        max={100}
                      />
                      <span
                        className="filledProgress"
                        style={{ width: `${rangeValue}%` }}
                      ></span>
                      <span
                        className="controller"
                        style={{ left: `calc(${rangeValue}% - 9px)` }}
                      ></span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                      <p className="progress-heading">Total Property</p>
                      <span className="valueSpan">{rangeValue}</span>
                    </div>
                    <div className="growBusiness_progress position-relative">
                      <Form.Range
                        value={rangeValue}
                        onChange={handleRangeChange}
                        min={0}
                        max={100}
                      />
                      <span
                        className="filledProgress"
                        style={{ width: `${rangeValue}%` }}
                      ></span>
                      <span
                        className="controller"
                        style={{ left: `calc(${rangeValue}% - 9px)` }}
                      ></span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                      <p className="progress-heading">Real Estate Investing</p>
                      <span className="valueSpan">{rangeValue}</span>
                    </div>
                    <div className="growBusiness_progress position-relative">
                      <Form.Range
                        value={rangeValue}
                        onChange={handleRangeChange}
                        min={0}
                        max={100}
                      />
                      <span
                        className="filledProgress"
                        style={{ width: `${rangeValue}%` }}
                      ></span>
                      <span
                        className="controller"
                        style={{ left: `calc(${rangeValue}% - 9px)` }}
                      ></span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div> */}

      <Footer />

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="filter-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={formik.handleSubmit}>
            <p className="mb-1 fw-semibold">Type</p>
            <div className="d-flex align-items-center gap-2 border-bottom pb-2 mb-2">
              <Form.Group controlId="validationCustom01">
                <Form.Control type="radio" name="category" value="" onChange={formik.handleChange} />
                <Form.Label>All</Form.Label>
              </Form.Group>
              {
                categories.length > 0 && categories.map((item) => {
                  return (
                    <Form.Group controlId={item?._id} key={item?._id}>
                      <Form.Control type="radio" name="category" value={item?._id} onChange={formik.handleChange} />
                      <Form.Label>{item?.title}</Form.Label>
                    </Form.Group>
                  )
                })
              }
            </div>

            <p className="mb-1 fw-semibold">Availability</p>
            <div className="d-flex align-items-center gap-2 border-bottom pb-2 mb-2">
              <Form.Group controlId="All">
                <Form.Control type="radio" name="funding_status" value="" onChange={formik.handleChange} />
                <Form.Label>All</Form.Label>
              </Form.Group>
              <Form.Group controlId="funding_starting_soon">
                <Form.Control type="radio" name="funding_status" value="funding_starting_soon" onChange={formik.handleChange} />
                <Form.Label>Funding Starting Soon</Form.Label>
              </Form.Group>
              <Form.Group controlId="funding_in_progress">
                <Form.Control type="radio" name="funding_status" value="funding_in_progress" onChange={formik.handleChange} />
                <Form.Label>Funding In Progress</Form.Label>
              </Form.Group>
              <Form.Group controlId="fully_funded">
                <Form.Control type="radio" name="funding_status" value="fully_funded" onChange={formik.handleChange} />
                <Form.Label>Fully funded</Form.Label>
              </Form.Group>
            </div>

            <p className="mb-1 fw-semibold">Location</p>
            <div className="border-bottom pb-2 mb-3">
              <ReactFlagsSelect
                searchable
                searchPlaceholder="Search country"
                selected={formik.values.country}
                onSelect={(code) => formik.setFieldValue("country", code)}
              />
              {/* <Form.Select aria-label="Default select example" name="location" onChange={formik.handleChange}>
                <option value="">All 60 Locations</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select> */}
            </div>

            <p className="mb-1 fw-semibold">Rent status</p>
            <div className="d-flex align-items-center gap-2 border-bottom pb-2 mb-2">
              <Form.Group controlId="All2">
                <Form.Control type="radio" name="rent_status" value="" onChange={formik.handleChange} />
                <Form.Label>All</Form.Label>
              </Form.Group>
              <Form.Group controlId="Rented">
                <Form.Control type="radio" name="rent_status" value="rented" onChange={formik.handleChange} />
                <Form.Label>Rented</Form.Label>
              </Form.Group>
              <Form.Group controlId="SeekingTenent">
                <Form.Control type="radio" name="rent_status" value="seeking_tenent" onChange={formik.handleChange} />
                <Form.Label>Seeking Tenent</Form.Label>
              </Form.Group>
            </div>
            <div className="mt-5">
              <Row>
                <Col md={6}>
                  <Button className="w-100 btn-secondary" type="reset">Clear Filters</Button>
                </Col>
                <Col md={6}>
                  <Button className="w-100" type="submit">Show Results</Button>
                </Col>
              </Row>
            </div>
          </form>

        </Offcanvas.Body>
        {/* <Offcanvas.Footer>
          <Button varient="primary">Show Results</Button>
        </Offcanvas.Footer> */}
      </Offcanvas>
    </>
  );
}

export default Invest;
