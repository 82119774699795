import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
    getBlogsThunk,
    getBlogDetailThunk
} from "./blogThunk";

const initialState = {
    blogs: [],
    total: 0,
    isLoading: false,
    limit: 10,
    page: 1,
    blog: {}
};

const blogSlice = createSlice({
    name: "blog",
    initialState,
    reducers: {
        changeLimit: (state, action) => {
            state.limit = action.payload;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBlogsThunk
                .fulfilled, (state, action) => {
                    if (action?.payload?.status === 200) {
                        state.blogs = action?.payload?.data?.body?.data;
                        state.total = action?.payload?.data?.body?.count;
                    }
                })

            .addCase(getBlogDetailThunk
                .fulfilled, (state, action) => {
                    if (action?.payload?.status === 200) {
                        state.blog = action?.payload?.data?.body?.data;
                        state.blogs = action?.payload?.data?.body?.blogs;
                    }
                })
    },
});

export const { changeLimit, changePage } = blogSlice.actions;
export default blogSlice.reducer;
