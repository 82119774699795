import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { verifyEmailThunk } from "../../features/auth/authThunk";
import { toast } from "react-hot-toast";

export default function VerifyEmail() {
    const navigate = useNavigate()
    const { userId, otp } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        verify();
    }, [])

    const verify = async () => {
        const resp = await dispatch(verifyEmailThunk({ userId, otp }));
        if (resp?.payload?.status === 200) {
            toast.success("Email verified successfully", {
                position: "bottom-left",
            });
            navigate("/login");
        } else {
            toast.error("Something went wrong. Please try again", {
                position: "bottom-left",
            });
        }
    }

    return (
        <>
        </>
    )
}

