import React, { useState, useEffect, useMemo } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Header from "../common/header";
import Footer from "../common/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faComment,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { getBlogsThunk } from "../../features/blog/blogThunk"
import { blogSelector } from "../../features/blog/blogSelector";
import {
  changeLimit,
  changePage,
} from "../../features/blog/blogSlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../features/auth/authSelector";
import ReactPaginate from "react-paginate";
import moment from "moment";

function Blog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { blogs, total, limit, page } = useSelector(blogSelector);
  const { token, userData } = useSelector(authSelector);

  const [configObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
    params: {
      limit: limit,
      page: page,
    },
  });

  const pageCount = useMemo(() => {
    const pageCounter = Math.ceil(total / limit);
    return pageCounter;
  }, [total, limit]);

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    await dispatch(getBlogsThunk({ ...configObject }));
  };

  const handlePageClick = async (data) => {
    await dispatch(changePage(data.selected + 1));
    await dispatch(
      getBlogsThunk({
        ...configObject,
        params: {
          limit: limit,
          page: data.selected + 1,
        },
      })
    );
  };

  return (
    <>
      <Header />

      {/* ====== common banner ======= */}
      <div className="static_banner position-relative">
        <Container>
          <div className="text-center">
            <h3>Blog</h3>
            <p>
              With an unwavering commitment to excellence, CrowdFina is on an{" "}
              <br /> odyssey to revolutionize the landscape of real estate.
            </p>
          </div>
          <Breadcrumb className="pages-breadcrumbs">
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Blog</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
        <img src={require("../../images/banner-wave.png")} alt="wave" className="static-banner-shape" />
      </div>

      <div className="common-padding">
        <Container>
          <Row>
            {
              total > 0 ? blogs.map((item) => {
                return (<Col lg={6} className="mb-3" key={item?._id}>
                  <div className="p-3 position-relative">
                    <div className="blog_img">
                      {
                        item?.image ? <img
                          src={`${process.env.REACT_APP_SERVER_URL}/uploads/blogs/${item?.image}`}
                          alt="blog"
                          className="w-100"
                        /> :
                          <img
                            src={require("../../images/faq.png")}
                            alt="blog"
                            className="w-100"
                          />
                      }

                    </div>
                    <div className="blog_content_wrapper p-4 mx-auto">
                      <ul className="ps-0 list-unstyled d-flex flex-wrap gap-4">
                        <li className="d-flex gap-2 align-items-center">
                          <div className="d-flex align-items-center justify-content-center admin_icon">
                            <FontAwesomeIcon icon={faUser} />
                          </div>
                          <p className="mb-0">{item?.user?.full_name}</p>
                        </li>
                        <li className="d-flex gap-2 align-items-center">
                          <div className="d-flex align-items-center justify-content-center admin_icon">
                            <FontAwesomeIcon icon={faCalendar} />
                          </div>
                          <p className="mb-0">{moment(item?.createdAt).format('DD MMMM, YYYY')}</p>
                        </li>
                        <li className="d-flex gap-2 align-items-center">
                          <div className="d-flex align-items-center justify-content-center admin_icon">
                            <FontAwesomeIcon icon={faComment} />
                          </div>
                          <p className="mb-0">Comments({item?.comments?.length})</p>
                        </li>
                      </ul>
                      <h4 className="blog_heading pe-4">
                        {item?.title}
                      </h4>
                      <p className="mb-0 blog_description">
                        {
                          item?.description?.length > 150 ? item?.description.substring(0, 150) + '...' : item?.description
                        }
                      </p>
                      <Link to={`/blog-detail/${item?._id}`} className="blog_btn px-4 mt-4 btn btn-primary">Read More</Link>
                    </div>
                  </div>
                </Col>)
              })
                : <>No Blog Found</>
            }
          </Row>
        </Container>
      </div>

      {/* ===== image gallery section ====== */}
      <div className="common-padding pt-0">
        <Container fluid>
          <div className="grid-gallery">
            <ul className="list-unstyled d-flex flex-wrap justify-content-between">
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary2.png")}
                    alt="gallary"
                  />
                </div>
              </li>
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary3.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
              </li>
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary2.png")}
                    alt="gallary"
                  />
                </div>{" "}
              </li>
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary3.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
              </li>
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary2.png")}
                    alt="gallary"
                  />
                </div>
              </li>
              <li>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary3.png")}
                    alt="gallary"
                  />
                </div>
                <div className="grid-item">
                  <img
                    src={require("../../images/gallary1.png")}
                    alt="gallary"
                  />
                </div>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      {/* ===== image gallery section ====== */}

      <Footer />
    </>
  );
}

export default Blog;
