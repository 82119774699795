import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
    getTutorialsThunk,
} from "./tutorialThunk";


const initialState = {
    tutorials: [],
    totalTutorials: 0,
    isLoading: false,
    tutorialLimit: 8,
    tutorialPage: 1,
};

const tutorialSlice = createSlice({
    name: "tutorial",
    initialState,
    reducers: {
        changeTutorialLimit: (state, action) => {
            state.tutorialLimit = action.payload;
        },
        changeTutorialPage: (state, action) => {
            state.tutorialPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTutorialsThunk.fulfilled, (state, action) => {
                if (action?.payload?.status === 200) {
                    state.tutorials = action?.payload?.data?.body?.data;
                    state.totalTutorials = action?.payload?.data?.body?.count;
                }
            })
    },
});

export const { changeTutorialLimit, changeTutorialPage } = tutorialSlice.actions;
export default tutorialSlice.reducer;
