import { faBell } from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faChevronRight,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { authSelector } from "../../features/auth/authSelector";
import { useDispatch, useSelector } from "react-redux";
import { logoutThunk, getProfileThunk } from "../../features/auth/authThunk";
import { toast } from "react-hot-toast";

function DashboardHeader({ toggleSidebar }) {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userData, token } = useSelector(authSelector);

  const [configObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  const logout = async () => {
    const response = await dispatch(logoutThunk());

    setTimeout(() => {
      navigate("/login");
      toast.success("Logout successfully");
      getData();
    }, 500);
  };

  const getData = async () => {
    await dispatch(getProfileThunk({ ...configObject }));
  };

  useEffect(() => {
    console.log("DashboardHeader rendered with user data:", userData);
  }, [userData]);

  let incompleteFieldMessage;

  switch (true) {
    // Check if phone number is empty or date of birth is empty
    case !userData?.phone_number ||
      userData.phone_number.length === 0 ||
      !userData?.dob ||
      userData.dob.length === 0:
      incompleteFieldMessage = (
        <div className="completeProfileHeader p-2">
          <p className="mb-0 text-center">
            You are just few clicks away from investing.{" "}
            <Link to="/profile">Complete my profile</Link>
          </p>
        </div>
      );
      break;

    case !userData?.identity_document_front ||
      !userData?.identity_document_back ||
      !userData?.bank_account_ownership_verification ||
      !userData?.account_number ||
      userData.account_number.length === 0:
      incompleteFieldMessage = (
        <div className="completeProfileHeader p-2">
          <p className="mb-0 text-center">
            You are just few clicks away from investing.{" "}
            <Link to="/profileComplete?type=1">Complete this step</Link>
          </p>
        </div>
      );
      break;

    case !userData?.professional_status ||
      userData.professional_status.length === 0 ||
      !userData?.estimated_total_wealth ||
      userData.estimated_total_wealth.length === 0 ||
      !userData?.investment_experience ||
      userData.investment_experience.length === 0:
      incompleteFieldMessage = (
        <div className="completeProfileHeader p-2">
          <p className="mb-0 text-center">
            You are just few clicks away from investing.{" "}
            <Link to="/profileComplete?type=2 ">Complete this step</Link>
          </p>
        </div>
      );
      break;

    case userData.phone_number.length != 0 &&
      userData.dob.length != 0 &&
      userData.account_number.length != 0 &&
      userData.estimated_total_wealth.length != 0 &&
      userData.investment_experience.length != 0 &&
      userData.documentVerified != true:
      incompleteFieldMessage = (
        <div className="completeProfileHeader p-2">
          <p className="mb-0 text-center">
            Your documents are currently under review
          </p>
        </div>
      );
      break;

    default:
      incompleteFieldMessage = null; // No incomplete fields
  }

  return (
    <>
      <div className="dashboard_header px-1 p-lg-4 py-2 py-lg-3 w-100 position-relative">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {/* <Button
            onClick={toggleSidebar}
            className="toggleButton rounded-circle d-flex align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Button> */}
            {/* <h3 className="mb-0 pageHeading d-none d-sm-block">Dashboard</h3> */}
            <div className="sidebar_logo text-center me-3">
              <Link to={"/portfolio"}>
                <img
                  src={require("../../images/logo.png")}
                  alt="logo"
                  width={150}
                />
              </Link>
            </div>
          </div>
          <div className="">
            <ul className="list-unstyled p-0 mb-0 d-none d-lg-flex navitems-list">
              <li
                className={location.pathname === "/portfolio" ? "active" : ""}
              >
                <Link to={"/portfolio"}>Dashboard</Link>
              </li>
              <li
                className={
                  location.pathname === "/propertylist" ? "active" : ""
                }
              >
                <Link to={"/propertylist"}>Invest</Link>
              </li>
              <li
                className={
                  location.pathname === "/learningcenter" ? "active" : ""
                }
              >
                <Link to={"/learningcenter"}>Learning</Link>
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center gap-3 gap-lg-4">
            <div
              className="d-block d-lg-none cursor-pointer"
              onClick={() => setIsVisible(!isVisible)}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
            {/* <div className="d-none d-lg-block">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </div>
            <div className="d-none d-lg-block">
              <FontAwesomeIcon icon={faBell} />
            </div> */}
            <div>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {userData?.profile_image !== "" ? (
                    <img
                      width={50}
                      height={50}
                      src={`${process.env.REACT_APP_SERVER_URL}/uploads/users/original/${userData?.profile_image}`}
                      alt="Img"
                      className="rounded-circle profile-img object-fit-cover"
                    />
                  ) : (
                    <img
                      height={50}
                      width={50}
                      src={require("../../images/user-profile-img.png")}
                      alt="Img"
                      className="rounded-circle profile-img object-fit-cover"
                    />
                  )}

                  {/* <img
                    src={require("../../images/testimonial-user.png")}
                    alt="investor"
                    width={50}
                    height={50}
                    className="rounded-circle profile-img"
                  /> */}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item href="/profile">Profile</Dropdown.Item> */}
                  <Dropdown.Item href="/profile">Settings</Dropdown.Item>
                  <Dropdown.Item onClick={logout}>Log out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div
          className={
            isVisible ? "rspv_header d-lg-none show" : "rspv_header d-lg-none"
          }
        >
          <ul className="list-unstyled p-0 mb-0 navitems-list d-block">
            <li className="active">
              <Link to={"/portfolio"}>Dashboard</Link>
            </li>
            <li>
              <Link to={"/invest"}>Invest</Link>
            </li>
            <li>
              <Link to={"/learningcenter"}>Learning</Link>
            </li>
            <li>
              <Link to={"#"}>Terms & Services</Link>
            </li>
          </ul>
        </div>
      </div>

      {/* {(userData?.address_proof?.length === 0 ||
        userData?.verification_document?.length === 0 ||
        userData?.investment_objectives?.length === 0 ||
        !userData?.investment_horizon) && (
        <div className="completeProfileHeader p-2">
          <p className="mb-0 text-center">
            You are just few clicks away from investing.{" "}
            <Link to="/profileComplete">Complete my profile</Link>
          </p>
        </div>
      )} */}

      {incompleteFieldMessage}
    </>
  );
}

export default DashboardHeader;
