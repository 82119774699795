import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;

export const getPropertiesThunk = createAsyncThunk(
    'properties/get',
    async ({ headers, params }) => {
        const config = {
            headers: { ...headers },
            params: params
        }
        try {
            const data = await axios.get(`${api_url}/properties/list`, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const getMyInvestedPropertiesThunk = createAsyncThunk(
    'properties/myinvestments',
    async ({ headers, params }) => {
        const config = {
            headers: { ...headers },
            params: params
        }
        try {
            const data = await axios.get(`${api_url}/properties/myinvestments`, config)
            return data

        } catch (error) {
            return error

        }
    }
)

export const getPropertyDetailThunk = createAsyncThunk(
    'properties/detail',
    async ({ headers, params }) => {
        const config = {
            headers: { ...headers },
        }
        try {
            const data = await axios.get(`${api_url}/properties/propertydetail/${params.id}`, config)
            return data

        } catch (error) {
            return error

        }
    }
)