export const propertySelector = (state) => {
    const {
        properties,
        total,
        isLoading,
        limit,
        page,

        myInvestProperties,
        investTotal,
        investLimit,
        investPage

    } = state.property

    return {
        properties,
        total,
        isLoading,
        limit,
        page,
        myInvestProperties,
        investTotal,
        investLimit,
        investPage
    }
}