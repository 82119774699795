import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import { withdrawMoneySchema } from "../../../utils/validationsSchema";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { authSelector } from "../../../features/auth/authSelector";
import Slider from "react-slick";
import Loader from "../../../Loader";
import { payoutThunk } from "../../../features/transaction/transactionThunk";
import { getProfileThunk } from "../../../features/auth/authThunk";

export default function WithdrawMoney(props) {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const { userData, token } = useSelector(authSelector);
    const [configObject, setConfigObject] = useState({
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    const formik = useFormik({
        initialValues: {
            amount: "",
        },
        validationSchema: withdrawMoneySchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                if (parseInt(userData?.wallet?.$numberDecimal ?? 0) < parseInt(values.amount)) {
                    toast.error('Insufficient balance');
                } else {
                    setLoading(true);
                    const response = await dispatch(payoutThunk({
                        ...configObject, body: values
                    }));

                    if (response?.payload?.status === 200) {
                        await dispatch(getProfileThunk({ ...configObject }));
                        toast.success('Payout added successfully');
                        props.onClose();
                    } else {
                        toast.error('Some problem exist. Please try again.');
                    }
                    setLoading(false);
                }
            } catch (error) {
                toast.error("Something went wrong.");
            }
        },
    });

    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Payout</Modal.Title>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter amount to withdraw"
                            name="amount"
                            min="1"
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                        />
                        <span style={{ color: "red" }}>
                            {formik.errors.amount ? (
                                <div>{formik.errors.amount} </div>
                            ) : null}{" "}
                        </span>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    {
                        isLoading ? <Loader /> : <Button variant="primary" type="submit" className="w-100">
                            Submit
                        </Button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
