import React, { useState, useEffect, useMemo } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import { Col, Container, Nav, Row, Tab, Table } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import AdminNavBar from "../AdminNavBar";
import { getInvestmentsThunk } from "../../../features/investment/investmentThunk";
import { investmentSelector } from "../../../features/investment/investmentSelector";
import { changeLimit, changePage } from "../../../features/investment/investmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import moment from "moment";
import { Chart } from "react-google-charts";
import { getMyInvestedPropertiesThunk } from "../../../features/property/propertyThunk";
import { propertySelector } from "../../../features/property/propertySelector";
import { getPropertyStatsThunk } from "../../../features/transaction/transactionThunk";
import ReactPaginate from "react-paginate";

function Transactions() {
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const { myInvestProperties, investTotal, investLimit, investPage } = useSelector(propertySelector);

  const toggleSidebar = () => setShowContent(!showContent);
  const percentage = 43;

  const { investments, total, limit, page } = useSelector(investmentSelector);
  const { token, userData } = useSelector(authSelector);
  const [propRevenue, setPropRevenue] = useState(0);
  const [investorCount, setInvestorCount] = useState(0);
  const [investAssetValue, setInvestAssetValue] = useState(0);
  const [data, setData] = useState([["Year", "Amount"]]);
  const [selectedTab, setSelectedTab] = useState("yearly");

  const [configObject] = useState({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: limit,
      page: 1,
    },
  });

  const pageCount = useMemo(() => {
    const pageCounter = Math.ceil(total / limit);
    return pageCounter;
  }, [total, limit]);

  useEffect(() => {
    getInvetsments();
    getProperties();
    getStats();
  }, []);

  const getInvetsments = async () => {
    await dispatch(getInvestmentsThunk({ ...configObject }));
  }

  const getProperties = async () => {
    await dispatch(getMyInvestedPropertiesThunk({ ...configObject }));
  }

  const getStats = async (type = "") => {
    const resp = await dispatch(getPropertyStatsThunk({
      ...configObject, params: {
        type: type
      }
    }));
    let investCount = 0;
    let assestValue = 0;
    let dataArr = [];
    if (type === "daily") {
      dataArr.push(["Date", "Amount"]);
    } else if (type === "monthly") {
      dataArr.push(["Month", "Amount"]);
    } else {
      dataArr.push(["Year", "Amount"])
    }

    if (resp?.payload?.status === 200) {
      const propRvnue = resp?.payload?.data?.body?.propertyInvestment
      const investorsByProp = resp?.payload?.data?.body?.investorsCountByProperty;
      const monthlyNetAmount = resp?.payload?.data?.body?.monthlyNetAmount;
      investorsByProp.length > 0 && investorsByProp.map((item) => {
        investCount += item?.investorCount;
        assestValue += parseInt(item?.propertyValue);
      });
      monthlyNetAmount.length > 0 && monthlyNetAmount.map((item) => {
        dataArr.push([item?.period, item?.netAmount]);
      })
      setData(dataArr);
      setInvestorCount(investCount);
      setInvestAssetValue(assestValue);
      setPropRevenue(propRvnue);
    }
  }

  const options = {
    chart: {
      title: "Total Revenue",
    },
  };

  const handleTabSelect = async (selected) => {
    setSelectedTab(selected);
    getStats(selected);
  };

  const handlePageClick = async (data) => {
    await dispatch(changePage(data.selected + 1));
    await dispatch(
      getInvestmentsThunk({
        ...configObject,
        params: {
          limit: limit,
          page: data.selected + 1,
        },
      })
    );
  };

  return (
    <>
      <div
        className={
          showContent
            ? "dashboard-main content-visible"
            : "dashboard-main"
        }
      >
        {/* <div>
          <AdminSidebar />
        </div> */}
        <div className="dashboard-body">
          <DashboardHeader toggleSidebar={toggleSidebar} />
          <Container>
            <AdminNavBar />
            <div className="dashboard-content p-2 p-md-4">
              <Row>
                <Col md={6} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                      <div className="card-headings">
                        <p className="mb-2">Total Properties </p>
                        <h3>{investTotal}</h3>
                      </div>
                      <div style={{ width: 100, height: 100 }}>
                        <CircularProgressbar
                          value={investTotal}
                          text={`${investTotal}%`}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                      <div className="card-headings">
                        <p className="mb-2">Properties Revenue</p>
                        <h3>€{propRevenue}</h3>
                      </div>

                      <div style={{ width: 100, height: 100 }}>
                        <CircularProgressbar
                          value={propRevenue > 0 && investAssetValue > 0 ? ((propRevenue / investAssetValue) * 100).toFixed(2) : 0}
                          text={`${propRevenue > 0 && investAssetValue > 0 ? ((propRevenue / investAssetValue) * 100).toFixed(2) : 0}%`}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                {/* <Col md={4} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                      <div className="card-headings">
                        <p className="mb-2">Total Investor</p>
                        <h3>{investorCount}</h3>
                      </div>
                      <div style={{ width: 100, height: 100 }}>
                        <CircularProgressbar
                          value={investTotal > 0 ? ((investTotal / investorCount) * 100).toFixed(2) : 0}
                          text={`${investTotal > 0 ? ((investTotal / investorCount) * 100).toFixed(2) : 0}%`}
                        />
                      </div>
                    </div>
                  </div>
                </Col> */}

                <Col md={12} className="mb-4">
                  <div className="dashboardCard p-4 h-100">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                      <Row className="align-items-center justify-content-between">
                        <Col sm={6}>
                          <h5>
                            Total Revenue
                          </h5>
                          <h2 className="fw-bold">
                            €{propRevenue}
                          </h2>
                        </Col>
                        <Col sm={6}>
                          <Nav className="totalRevenue_tabs justify-content-end" onSelect={handleTabSelect} activeKey={selectedTab}>
                            <Nav.Item>
                              <Nav.Link eventKey="yearly" className={selectedTab == 'yearly' ? 'active' : ''}>Yearly</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="monthly">Monthly</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="daily">Daily</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col sm={12}>
                          {
                            data.length > 1 ? <Chart
                              chartType="Bar"
                              width="100%"
                              height="400px"
                              data={data}
                              options={options}
                            />
                              : <p className="text-center">No chart data available</p>
                          }

                          {/* <Tab.Content>
                            <Tab.Pane eventKey="yearly">
                            </Tab.Pane>
                          </Tab.Content> */}
                        </Col>
                      </Row>
                    </Tab.Container>

                  </div>
                </Col>
              </Row>

              <div className="table-responsive theme-table">
                <h5 className="Cardheading mx-3">Transactions</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Property Name</th>
                      <th>Transaction token Quantity</th>
                      <th>Token Price</th>
                      <th>Type</th>
                      <th>Transaction amount</th>
                      <th>Transaction Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      total > 0 ? investments.map((item) => {
                        return (<tr key={item?._id}>
                          <td>{item?.property?.name}</td>
                          <td>{item?.type == 'buy' ? `+${item?.tokenQuantity}` : `-${item?.tokenQuantity}`}</td>
                          <td className="text-capitalize">{item?.type}</td>
                          <td>€{item?.tokenAmount}</td>
                          <td>€{item?.amount}</td>
                          <td>{moment(item?.createdAt).format('DD MMM, YYYY')}</td>
                        </tr>)
                      })
                        :
                        <tr>
                          <td colSpan={5} className="text-center">No record found</td>
                        </tr>
                    }
                  </tbody>
                </Table>
              </div>

              <div className="mt-4">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount === 1 ? 1 : pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"pagination_prev"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"pagination_next"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>

            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Transactions;
