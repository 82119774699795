import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgotPasswordThunk } from "../../features/auth/authThunk";
import { forgotSchema } from "../../utils/validationsSchema";
import { toast } from "react-hot-toast";

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      try {
        const response = await dispatch(forgotPasswordThunk(values));
        console.log("res=====>", response);
        if (response?.payload?.status === 200) {
          toast.success("Reset link sent on your registered email", {
            position: "top-right",
          });
          formik.resetForm();
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  });

  return (
    <>
      <div className="authWrapper">
        <Row className="m-0 align-items-center">
          <Col lg={8} md={6} className="ps-md-0">
            <img
              src={require("../../images/authbanner.png")}
              className="login-side-img d-none d-md-block"
            />
          </Col>
          <Col lg={4} md={6}>
            <div className="logo_div text-center pt-4">
              <img src={require("../../images/logo.png")} alt="" />
            </div>
            <div className="login-form-wrapper">
              <h3 className="text-center">Forgot Password</h3>
              <p className="text-center mb-4 welcome-back-content">
                Enter Your email to reset your password
              </p>
              <div className="form-inner-div mx-auto">
                <form onSubmit={formik.handleSubmit}>
                  <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      type="text"
                    />
                    <span style={{ color: "red" }}>
                      {formik.errors.email ? (
                        <div>{formik.errors.email} </div>
                      ) : null}{" "}
                    </span>
                  </Form.Group>



                  <Button type="submit" className="login-btn px-4 w-100">
                    Send
                  </Button>
                </form>
                <div className="text-center mt-4 forgot-pass-div">
                  <Link to={"/login"}>Back to Login</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ForgotPassword;
