import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL_DEV;

export const getFaqsThunk = createAsyncThunk(
    'faqs/get',
    async ({ headers, params }) => {
        const config = {
            headers: { ...headers },
            params: params
        }
        try {
            const data = await axios.get(`${api_url}/faqs/list`, config)
            return data

        } catch (error) {
            return error

        }
    }
)
