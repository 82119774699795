import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import { withdrawMoneySchema } from "../../../utils/validationsSchema";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { authSelector } from "../../../features/auth/authSelector";
import Slider from "react-slick";
import Loader from "../../../Loader";
import { approvedPayoutThunk, withdrawPayoutThunk } from "../../../features/transaction/transactionThunk";
import { transactionSelector } from "../../../features/transaction/transactionSelector";
import moment from "moment";

export default function ApprovedPayout(props) {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const { userData, token } = useSelector(authSelector);
    const { payoutTotal, payouts } = useSelector(transactionSelector);
    const [checkedItems, setCheckedItems] = useState([]);
    const [configObject, setConfigObject] = useState({
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    useEffect(() => {
        getPayouts();
    }, []);

    const getPayouts = async () => {
        await dispatch(approvedPayoutThunk({ ...configObject }));
    }

    const handleSingleCheck = (id) => {
        if (checkedItems.includes(id)) {
            const newCheckedItems = checkedItems.filter((itemId) => itemId !== id);
            setCheckedItems(newCheckedItems);
        } else {
            setCheckedItems([...checkedItems, id]);
        }
    };

    const handleCheckAll = () => {
        if (checkedItems.length === payoutTotal) {
            setCheckedItems([]);
        } else {
            setCheckedItems(payouts.map((item) => item._id));
        }
    };

    const handleWithdraw = async () => {
        if (checkedItems.length > 0) {
            const response = await dispatch(withdrawPayoutThunk({
                ...configObject, body: {
                    itemIds: checkedItems
                }
            }));
            if (response.payload.status === 200) {
                toast.success('Payout withdraw successfully');
                props.onClose();
            } else {
                toast.error('Some problem exist. Please try again.');
            }
        } else {
            toast.error("No item selected to withdraw")
        }
    }

    return (
        <Modal show={props.show} onHide={props.onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Payout List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive theme-table">
                    <Table>
                        <thead>
                            <tr>
                                <th align="center" style={{ width: "50px" }}>
                                    <Form className="form_chekbox">
                                        <Form.Group controlId="formBasicCheckbox1">
                                            <Form.Check
                                                type="checkbox"
                                                className="d-inline-flex align-items-center"
                                                checked={payoutTotal === checkedItems.length}
                                                onChange={handleCheckAll}
                                            />
                                        </Form.Group>
                                    </Form>
                                </th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                payoutTotal > 0 ? payouts.map((item) => {
                                    return (<tr key={item?._id}>
                                        <td align="center" style={{ minWidth: "50px" }}>
                                            <Form className="form_chekbox">
                                                <Form.Group controlId="trashNote{item._id}">
                                                    <Form.Check type="checkbox"
                                                        className="d-inline-flex align-items-center"
                                                        checked={checkedItems.includes(item._id)}
                                                        onChange={() => handleSingleCheck(item._id)}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </td>
                                        <td>${item?.amount}</td>
                                        <td>{moment(item?.createdAt).format('DD MMMM, YYYY')}</td>
                                        <td>{moment(item?.createdAt).format('hh:mm A')}</td>
                                        <td>
                                            <span className={`dashboard_badge text-capitalize ${item?.status === 'approved' ? 'success' : 'warning'}`}>
                                                {item?.status}
                                            </span>
                                        </td>
                                    </tr>)
                                })
                                    :
                                    <tr><td colSpan={5} className="text-center">No record found</td></tr>
                            }
                        </tbody>
                    </Table>
                </div>

            </Modal.Body>
            <Modal.Footer>
                {
                    isLoading ? <Loader /> : <Button variant="primary" className="w-100" onClick={handleWithdraw}>
                        Withdraw
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}
