import React, { useState } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import { useFormik } from "formik";
import AdminNavBar from "../AdminNavBar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SettingData from "./settingData";
import { toast } from "react-hot-toast";
import { changeStripeConnectThunk, getProfileThunk, removeBankThunk } from "../../../features/auth/authThunk";
import { addStripeConnect } from "../../../utils/validationsSchema";
import axios from "axios";
import Loader from "../../../Loader";
import { useStripe } from '@stripe/react-stripe-js';
import moment from "moment";

function StripeConnect() {
    const [showContent, setShowContent] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const toggleSidebar = () => setShowContent(!showContent);
    const { userData, token } = useSelector(authSelector);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [frontIdentity, setFrontIdentity] = useState("");
    const [backIdentity, setBackIdentity] = useState("");
    const stripe = useStripe();

    const [configObject, setConfigObject] = useState({
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    const formik = useFormik({
        initialValues: {
            account_holder_name: "",
            account_number: "",
            //routing_number: "",
            account_address: "",
            account_city: "",
            account_state: "",
            account_country: "",
            account_postal_code: "",
            //url: userData?.url,
            //ssn_last_4: userData?.ssn_last_4,
            stripe_id_number: "",
            identity_document_front: "",
            identity_document_back: "",
            bank_account_ownership_verification: "",
            _id: userData?._id,
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                if (!userData?.dob || userData?.dob == '') {
                    toast.error('Please complete basic details first');

                } else {
                    let formData = new FormData();
                    formData.append("purpose", "identity_document");
                    formData.append("file", values.identity_document_front);
                    let frontDoc = await axios.post(
                        `https://files.stripe.com/v1/files`,
                        formData,
                        {
                            headers: {
                                "Authorization": `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                                "Content-Type": "application/x-www-form-urlencoded",
                            },
                        }
                    );
                    if (frontDoc?.status === 200) {
                        values.frontIdentityId = frontDoc?.data?.id;
                    } else {
                        toast.error('Identity Document Front is not uploaded');
                    }

                    let formData1 = new FormData();
                    formData1.append("purpose", "identity_document");
                    formData1.append("file", values.identity_document_back);
                    let backDoc = await axios.post(
                        `https://files.stripe.com/v1/files`,
                        formData1,
                        {
                            headers: {
                                "Authorization": `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                                "Content-Type": "application/x-www-form-urlencoded",
                            },
                        }
                    );
                    if (backDoc?.status === 200) {
                        values.backIdentityId = backDoc?.data?.id;
                    } else {
                        toast.error('Identity Document Back is not uploaded');
                    }

                    let formData2 = new FormData();
                    formData2.append("purpose", "account_requirement");
                    formData2.append("file", values.bank_account_ownership_verification);
                    let ownerVerificationDoc = await axios.post(
                        `https://files.stripe.com/v1/files`,
                        formData2,
                        {
                            headers: {
                                "Authorization": `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                                "Content-Type": "application/x-www-form-urlencoded",
                            },
                        }
                    );
                    if (ownerVerificationDoc?.status === 200) {
                        values.bank_account_ownership_verification = ownerVerificationDoc?.data?.id;
                    } else {
                        toast.error('Owner verification document is not uploaded');
                    }

                    let nameArr = userData.full_name.split(' ');
                    let dobArr = userData.dob.split('-');
                    const accountResult = await stripe.createToken('account', {
                        business_type: 'individual',
                        individual: {
                            first_name: nameArr[0],
                            last_name: nameArr.length > 1 ? nameArr[1] : nameArr[0],
                            email: userData.email,
                            phone: userData.phone_number,
                            id_number: values.stripe_id_number,
                            dob: {
                                year: dobArr.length > 0 ? dobArr[0] : '1990',
                                month: dobArr.length > 1 ? dobArr[1] : '01',
                                day: dobArr.length > 2 ? dobArr[2] : '01',
                            },
                            address: {
                                line1: values.account_address,
                                city: values.account_city,
                                state: values.account_state,
                                country: values.account_country,
                                postal_code: values.account_postal_code
                            }
                        },
                        tos_shown_and_accepted: true,
                    });

                    if (accountResult) {
                        values.account_token = accountResult.token.id
                    }

                    const personResult = await stripe.createToken('person', {
                        person: {
                            first_name: nameArr[0],
                            last_name: nameArr.length > 1 ? nameArr[1] : nameArr[0],
                            address: {
                                line1: values.account_address,
                                city: values.account_city,
                                state: values.account_state,
                                country: values.account_country,
                                postal_code: values.account_postal_code
                            }
                        },
                    });

                    if (personResult) {
                        values.person_token = personResult.token.id
                    }

                    if (backDoc?.status === 200 && frontDoc?.status === 200 && ownerVerificationDoc?.status === 200) {
                        const detailResult = await stripe.createToken('account', {
                            individual: {
                                verification: {
                                    document: {
                                        front: frontDoc?.data?.id, // URL of the new front image
                                        back: backDoc?.data?.id, // URL of the new back image
                                    },
                                },
                            },
                            documents: {
                                bank_account_ownership_verification: {
                                    files: [ownerVerificationDoc?.data?.id]
                                }
                            },
                            tos_shown_and_accepted: true,
                        });

                        if (detailResult) {
                            values.person_detail_token = detailResult.token.id
                        }
                    }

                    setLoading(true);
                    if (accountResult && personResult) {
                        const response = await dispatch(changeStripeConnectThunk({ ...configObject, body: values }));

                        console.log(response)
                        if (response?.payload?.status === 200) {
                            toast.success(response?.payload?.data?.message);
                            resetForm();
                        } else {
                            toast.error(response?.payload?.response?.data?.message);
                        }
                    } else {
                        toast.error('Document is not uploaded');
                    }
                    setLoading(false);
                }

            } catch (error) {
                setLoading(false);
                console.log(error)
                //toast.error("Something went wrong.");
            }

        },
        validationSchema: addStripeConnect,
        validateOnMount: true,
    });

    const handleIdentityFront = (e) => {
        formik.setFieldValue("identity_document_front", e.target.files[0]);
    };

    const handleIdentityBack = (e) => {
        formik.setFieldValue("identity_document_back", e.target.files[0]);
    };

    const handleBankVerification = (e) => {
        formik.setFieldValue("bank_account_ownership_verification", e.target.files[0]);
    };

    const removeBankAccout = async () => {
        setLoading(true);
        const resp = await dispatch(removeBankThunk({
            ...configObject, body: {
                account_holder_name: null,
                account_number: null,
                routing_number: null,
                account_address: null,
                account_city: null,
                account_state: null,
                account_country: null,
                account_postal_code: null,
                url: null,
                stripe_account_id: null,
                stripe_bank_id: null,
                stripe_person_id: null,
                ssn_last_4: null,
                stripe_id_number: null,
            }
        }));
        if (resp?.payload?.status === 200) {
            await dispatch(getProfileThunk({ ...configObject }));
            toast.success('Account details removed successfully');
        } else {
            toast.error(resp?.payload?.response?.data?.message);
        }
        setLoading(false);
    }


    return (
        <>
            <div
                className={
                    showContent ? "dashboard-main content-visible" : "dashboard-main"
                }
            >
                {/* <div>
          <AdminSidebar />
        </div> */}
                <div className="dashboard-body">
                    <DashboardHeader toggleSidebar={toggleSidebar} />
                    <Container>
                        <AdminNavBar />
                        <div className="dashboard-content pofile_page p-2 p-md-4">
                            <Row>
                                <Col lg={5} className="mb-4">
                                    <div className="dashboardCard overflow-hidden">
                                        <SettingData />
                                        <div className="p-4 text-center">
                                            <Link className={` btn border-0 py-2 px-4 mb-4 `} to="/profile">
                                                Basic Details
                                            </Link>
                                            <div className="d-flex align-items-center flex-column gap-4">
                                                <Link className={`text-muted fw-semibold`} to="/profileComplete"
                                                    state={{ step: 1 }}>
                                                    Documents
                                                </Link>
                                                <Link className={`text-muted fw-semibold`} to="/profileComplete"
                                                    state={{ step: 2 }}>
                                                    Investment Objectives
                                                </Link>
                                                <Link className={`text-muted fw-semibold`} to="/changepassword">Password</Link>
                                                <Link className={`${location.pathname === "/bankdetails" ? "bg-black text-white" : "text-dark"} btn border-0 py-2 px-4 `} to="/bankdetails">Bank Details</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={7} className="investor-profile-wrapper p-0">
                                    <div className="dashboardCard p-4 mb-4">
                                        {
                                            userData?.stripe_account_id ? <>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Account holder name: </Form.Label>
                                                    {' ' + userData?.account_holder_name}
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Account number: </Form.Label>
                                                    {' ' + userData?.account_number}
                                                </Form.Group>
                                                {/* <Form.Group className="mb-3">
                                                    <Form.Label>Routing number: </Form.Label>
                                                    {' ' + userData?.routing_number}
                                                </Form.Group> */}
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Account address: </Form.Label>
                                                    {' ' + userData?.account_address + ', ' + userData?.account_city + ', ' + userData?.account_state + ', ' + userData?.account_country + ', ' + userData?.account_postal_code}
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Government ID number: </Form.Label>
                                                    {' ' + userData?.stripe_id_number}
                                                </Form.Group>
                                                {
                                                    isLoading ? <Loader /> : <Button variant="danger" className="px-4" onClick={removeBankAccout}>
                                                        Remove Account
                                                    </Button>
                                                }

                                            </> : <Form onSubmit={formik.handleSubmit}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Account holder name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Account holder name"
                                                        name="account_holder_name"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.account_holder_name}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.account_holder_name ? (
                                                            <div>{formik.errors.account_holder_name} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Account number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Account number"
                                                        name="account_number"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.account_number}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.account_number ? (
                                                            <div>{formik.errors.account_number} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group>
                                                {/* <Form.Group className="mb-3">
                                                    <Form.Label>Routing number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Routing Number"
                                                        name="routing_number"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.routing_number}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.routing_number ? (
                                                            <div>{formik.errors.routing_number} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group> */}
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Address"
                                                        name="account_address"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.account_address}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.account_address ? (
                                                            <div>{formik.errors.account_address} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="City"
                                                        name="account_city"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.account_city}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.account_city ? (
                                                            <div>{formik.errors.account_city} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="State"
                                                        name="account_state"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.account_state}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.account_state ? (
                                                            <div>{formik.errors.account_state} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Country"
                                                        name="account_country"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.account_country}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.account_country ? (
                                                            <div>{formik.errors.account_country} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Postal code</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Postal Code"
                                                        name="account_postal_code"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.account_postal_code}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.account_postal_code ? (
                                                            <div>{formik.errors.account_postal_code} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group>
                                                {/* <Form.Group className="mb-3">
                                                    <Form.Label>Website URL</Form.Label>
                                                    <Form.Control
                                                        type="url"
                                                        placeholder="Website URL"
                                                        name="url"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.url}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.url ? (
                                                            <div>{formik.errors.url} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group> */}
                                                {/* <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="SSN (Last 4 Digit)"
                                                    name="ssn_last_4"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.ssn_last_4}
                                                />
                                                <span style={{ color: "red" }}>
                                                    {formik.errors.ssn_last_4 ? (
                                                        <div>{formik.errors.ssn_last_4} </div>
                                                    ) : null}{" "}
                                                </span>
                                            </Form.Group> */}
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Government issue ID number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Government issue ID number"
                                                        name="stripe_id_number"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.stripe_id_number}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.stripe_id_number ? (
                                                            <div>{formik.errors.stripe_id_number} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Identity Document Front</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        placeholder="Identity Document Front-Side"
                                                        name="identity_document_front"
                                                        onChange={(e) => handleIdentityFront(e)}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.identity_document_front ? (
                                                            <div>{formik.errors.identity_document_front} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Identity Document Back</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        placeholder="Identity Document Back-Side"
                                                        name="identity_document_back"
                                                        onChange={(e) => handleIdentityBack(e)}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.identity_document_back ? (
                                                            <div>{formik.errors.identity_document_back} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Bank Owner Verification Document </Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        placeholder="Bank Owner Verification Document "
                                                        name="bank_account_ownership_verification"
                                                        onChange={(e) => handleBankVerification(e)}
                                                    />
                                                    <span style={{ color: "red" }}>
                                                        {formik.errors.bank_account_ownership_verification ? (
                                                            <div>{formik.errors.bank_account_ownership_verification} </div>
                                                        ) : null}{" "}
                                                    </span>
                                                </Form.Group>
                                                {
                                                    isLoading ? <Loader /> : <Button type="submit" className="px-4">
                                                        Save
                                                    </Button>
                                                }

                                            </Form>
                                        }

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default StripeConnect;
