import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { updateImageThunk } from "../../../features/auth/authThunk";
import { toast } from "react-hot-toast";

function SettingData(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const { userData, token } = useSelector(authSelector);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [image, setImage] = useState(require('../../../images/dummy.jpg'));
    const [imgChange, setImgChange] = useState(false);
    const [configObject, setConfigObject] = useState({
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    const formik = useFormik({
        initialValues: {
            profile_image: []
        },
        onSubmit: async (values, { resetForm }) => {
            let formData = new FormData();
            formData.append("image", values.profile_image);

            const response = await dispatch(updateImageThunk({ ...configObject, body: formData }));
            if (response?.payload?.status === 200) {
                toast.success('user Image updated successfully');
                resetForm();
                setShow(false);
            } else {
                toast.error(response?.payload?.response?.data?.message);
            }
        },
    });

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
            formik.setFieldValue('profile_image', event.target.files[0]);
            setImgChange(true);
        }
    }

    return (
        <>
            <div className="position-relative">

                {
                    userData?.profile_image !== "" ?
                        <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/users/original/${userData?.profile_image}`} alt="Img" className="user-img" />
                        : <img src={require("../../../images/user-profile-img.png")} alt="Img" className="user-img" />
                }
                {/* <img src={require("../../../images/user-profile-img.png")} alt="Img" className="user-img" /> */}
                <div className="edit-img" onClick={() => setShow(true)}>
                    <Link className="rounded-circle d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </Link>
                </div>
            </div>
            <div className="p-4 border-bottom">
                <div className="d-flex gap-2 align-items-center justify-content-center mb-3">
                    <span className="text-muted">Name :</span>
                    <p className="mb-0 fw-semibold">
                        {userData?.first_name
                            ? `${userData?.first_name} ${userData?.last_name}`
                            : userData?.full_name}
                    </p>
                </div>
                <div className="d-flex gap-2 align-items-center justify-content-center mb-3">
                    <span className="text-muted">Email :</span>
                    <p className="mb-0 fw-semibold">{userData?.email}</p>
                </div>
                <div className="d-flex gap-2 align-items-center justify-content-center mb-3">
                    <span className="text-muted">Address :</span>
                    <p className="mb-0 fw-semibold">{userData?.address}</p>
                </div>
            </div>

            <Modal show={show} onHide={() => { setShow(false); setImgChange(false) }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change Profile Image</Modal.Title>
                </Modal.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="upload-img-container position-relative">
                            {
                                userData?.profile_image !== "" && !imgChange ?

                                    <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/users/original/${userData?.profile_image}`} alt="Img" />
                                    : <img src={image} alt="Img" />
                            }

                            <div className="uplaod-file d-flex align-items-center justify-content-center">
                                <input type="file" className="form-control" onChange={onImageChange} />
                                <span className="text-white">Upload File</span>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default SettingData;
