import React, { useState } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../../features/auth/authSelector";
import { useFormik } from "formik";
import { changePasswordThunk } from "../../../features/auth/authThunk";
import { changePasswordSchema } from "../../../utils/validationsSchema";
import AdminNavBar from "../AdminNavBar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SettingData from "./settingData";
import { toast } from "react-hot-toast";

function ChangePassword() {
    const [showContent, setShowContent] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const toggleSidebar = () => setShowContent(!showContent);
    const { userData, token } = useSelector(authSelector);
    const dispatch = useDispatch();
    const [configObject, setConfigObject] = useState({
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            _id: userData?._id,
        },
        onSubmit: async (values, { resetForm }) => {
            const response = await dispatch(changePasswordThunk({ ...configObject, body: values }));
            if (response?.payload?.status === 200) {
                toast.success('Password changed successfully');
                resetForm();
            } else {
                toast.error(response?.payload?.response?.data?.message);
            }
        },
        validationSchema: changePasswordSchema,
        validateOnMount: true,
    });

    return (
        <>
            <div
                className={
                    showContent ? "dashboard-main content-visible" : "dashboard-main"
                }
            >
                {/* <div>
          <AdminSidebar />
        </div> */}
                <div className="dashboard-body">
                    <DashboardHeader toggleSidebar={toggleSidebar} />
                    <Container>
                        <AdminNavBar />
                        <div className="dashboard-content pofile_page p-2 p-md-4">
                            <Row>
                                <Col lg={5} className="mb-4">
                                    <div className="dashboardCard overflow-hidden">
                                        <SettingData />
                                        <div className="p-4 text-center">
                                            <Link className={` btn border-0 py-2 px-4 mb-4 `} to="/profile">
                                                Basic Details
                                            </Link>
                                            <div className="d-flex align-items-center flex-column gap-4">
                                                <Link className={`text-muted fw-semibold`} to="/profileComplete"
                                                    state={{ step: 1 }}>
                                                    Documents
                                                </Link>
                                                <Link className={`text-muted fw-semibold`} to="/profileComplete"
                                                    state={{ step: 2 }}>
                                                    Investment Objectives
                                                </Link>
                                                <Link className={`${location.pathname === "/changepassword" ? "bg-black text-white" : "text-dark"} btn border-0 py-2 px-4 `} to="/changepassword">Password</Link>

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={7} className="investor-profile-wrapper p-0">
                                    <div className="dashboardCard p-4 mb-4 ">
                                        <Form onSubmit={formik.handleSubmit}>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Old Password"
                                                    name="oldPassword"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.oldPassword}
                                                />
                                                <span style={{ color: "red" }}>
                                                    {formik.errors.oldPassword ? (
                                                        <div>{formik.errors.oldPassword} </div>
                                                    ) : null}{" "}
                                                </span>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="password"
                                                    placeholder="New Password"
                                                    name="newPassword"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.newPassword}
                                                />
                                                <span style={{ color: "red" }}>
                                                    {formik.errors.newPassword ? (
                                                        <div>{formik.errors.newPassword} </div>
                                                    ) : null}{" "}
                                                </span>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    name="confirmPassword"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.confirmPassword}
                                                />
                                                <span style={{ color: "red" }}>
                                                    {formik.errors.confirmPassword ? (
                                                        <div>{formik.errors.confirmPassword} </div>
                                                    ) : null}{" "}
                                                </span>
                                            </Form.Group>
                                            <Button type="submit" className="px-4">
                                                Save
                                            </Button>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;
