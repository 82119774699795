import * as Yup from "yup";
import moment from "moment";

const isOlderThan13 = (date) => {
  return moment().diff(moment(date, 'YYYY-MM-DD'), 'years') >= 13;
};

export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
      "Password must contain at least one digit, one lowercase letter, one uppercase letter, and one special character"
    )
    .required("Required!"),
});
export const signupValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
  password: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
      "Password must contain at least one digit, one lowercase letter, one uppercase letter, and one special character"
    )
    .required("Required!"),
  username: Yup.string().required("Required!"),
  full_name: Yup.string().required("Required!"),
});

export const userUpdateProfileValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
  full_name: Yup.string().required("Required!"),
  phone_number: Yup.string().required("Required!"),
  dob: Yup.date().required('Date of birth is required').max(new Date(), 'Invalid date of birth').test('is-older-than-13', 'You must be older than 13 years', (value) => {
    return isOlderThan13(value);
  }),
});
export const forgotSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
});
export const resetPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
      "Password must contain at least one digit, one lowercase letter, one uppercase letter, and one special character"
    )
    .required("Required!"),
  confirmPassword: Yup.string()
    .required("Required!")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
});
export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string().required("Required!"),
  newPassword: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
      "Password must contain at least one digit, one lowercase letter, one uppercase letter, and one special character"
    )
    .required("Required!"),
  confirmPassword: Yup.string()
    .required("Required!")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
});
export const subscriberSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
});

export const investProfileStep2Schema = Yup.object({

  investment_horizon: Yup.string().required("Required!"),
  sustainability: Yup.string().required("Required!"),
  ability_to_bear_losses: Yup.string().required("Required!"),
  investment_experience: Yup.string().required("Required!"),
  professional_status: Yup.string().required("Required!"),
  annual_income_range: Yup.string().required("Required!"),
  estimated_total_wealth: Yup.string().required("Required!"),
  investment_objectives: Yup.array().of(Yup.string().required('Required!')).required('Required!'),
  //income_currency: Yup.string().required("Required!"),
});

export const addCardSchema = Yup.object({
  //card_no: Yup.string().matches(/^\d{16}$/, 'Must be a 16-digit numeric card number').required("Required!"),
  holder_name: Yup.string().required("Required!"),
  //exp_date: Yup.string().matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Invalid date format (MM/YYYY)').required("Required!"),
  //cvv: Yup.string().matches(/^\d{3}$/, 'Must be a 3-digit numeric card number').required("Required!"),
});

export const addmoneySchema = Yup.object({
  card_no: Yup.string().required("Required!"),
  amount: Yup.number().required("Required!"),
});

export const addCommentSchema = Yup.object({
  content: Yup.string().required("Required!"),
});

export const withdrawMoneySchema = Yup.object({
  amount: Yup.number().required("Required!"),
});

export const addStripeConnect = Yup.object({
  account_holder_name: Yup.string().required("Required!"),
  account_number: Yup.string().required("Required!"),
  //routing_number: Yup.string().required("Required!"),
  account_address: Yup.string().required("Required!"),
  account_city: Yup.string().required("Required!"),
  account_state: Yup.string().required("Required!"),
  account_country: Yup.string().required("Required!"),
  account_postal_code: Yup.string().required("Required!"),
  //url: Yup.string().required("Required!"),
  identity_document_front: Yup.string().required("Required!"),
  identity_document_back: Yup.string().required("Required!"),
  stripe_id_number: Yup.number().required("Required!"),
  bank_account_ownership_verification: Yup.string().required("Required!"),
  //ssn_last_4: Yup.string().matches(/^\d{4}$/, 'Must be a 4-digit numeric number').required("Required!"),
});

export const contactValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Required!"),
  name: Yup.string().required("Required!"),
  message: Yup.string().required("Required!"),
});