import ReactDOM from "react-dom/client";
import Login from "./components/auth/Login";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./components/landing";
import ForgotPassword from "./components/auth/ForgotPassword";
import AboutUs from "./components/static_pages/AboutUs";
import Blog from "./components/static_pages/Blog";
import Invest from "./components/static_pages/invest";
import Dashboard from "./components/Dashboard/Dashboard";
import ResetPassword from "./components/auth/ResetPassword";
import SignUp from "./components/auth/Signup";
import InvstorDashboard from "./components/Admin/InvstorDashboard";
import InvestorProfile from "./components/investor/InvestorProfile";
import AdminPortfolio from "./components/Admin/AdminPortfolio";
import DashboardInvest from "./components/Admin/Invest";
import PropertyDetail from "./components/Admin/Invest/PropertyDetail";
import SingleInvest from "./components/Admin/SingleInvest";
import Transactions from "./components/Admin/Transactions";
import Wallet from "./components/Admin/Wallet";
import LearningCenter from "./components/Admin/LearningCenter";
import ProfilePage from "./components/Admin/ProfilePage";
import ProtectedRoutes from "./components/auth/ProtectedRoutes";
import VerifyEmail from "./components/auth/VerifyEmail";
import ProfilePageTwo from "./components/Admin/ProfilePageTwo";
import ChangePassword from "./components/Admin/ProfilePage/changepassword";
import PageNotFound from "./components/common/PageNotFound";
import BLogDetail from "./components/static_pages/BLogDetail";
import StripeConnect from "./components/Admin/ProfilePage/stripeconnect";
import ConatctUs from "./components/static_pages/ConatctUs";

export default function AppRoutes() {
  return (
    <>
      <Routes>
        {/* <Route path="/home" element={<Dashboard />}/>   */}


        {/* Auth Routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route path="/verifyemail/:userId/:otp" element={<VerifyEmail />} />

        {/* Static page Routes */}

        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ConatctUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/invest" element={<Invest />} />
        <Route path="/blog-detail/:id" element={<BLogDetail />} />

        {/* Static page Routes end */}

        {/* User Dashboard Routes */}
        {/* <Route path="/invest-profile" element={<ProtectedRoutes Component={InvestorProfile} />} /> */}
        <Route path="/investors" element={<ProtectedRoutes Component={InvstorDashboard} />} />
        <Route path="/portfolio" element={<ProtectedRoutes Component={AdminPortfolio} />} />
        <Route path="/dashboardinvest" element={<ProtectedRoutes Component={DashboardInvest} />} />
        <Route path="/propertydetails" element={<ProtectedRoutes Component={PropertyDetail} />} />
        <Route path="/propertylist" element={<ProtectedRoutes Component={SingleInvest} />} />
        <Route path="/transactions" element={<ProtectedRoutes Component={Transactions} />} />
        <Route path="/wallet" element={<ProtectedRoutes Component={Wallet} />} />
        <Route path="/learningcenter" element={<ProtectedRoutes Component={LearningCenter} />} />
        <Route path="/profile" element={<ProtectedRoutes Component={ProfilePage} />} />
        <Route path="/profileComplete" element={<ProtectedRoutes Component={ProfilePageTwo} />} />
        <Route path="/changepassword" element={<ProtectedRoutes Component={ChangePassword} />} />
        <Route path="/bankdetails" element={<ProtectedRoutes Component={StripeConnect} />} />
        <Route path="*" element={<PageNotFound />} />

        {/*Admin Routes */}
      </Routes>
    </>

  );
}
