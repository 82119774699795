
export const cardSelector = (state) => {
    const {
        cards,
        total,
        isLoading,
        limit,
        page
    } = state.card

    return {
        cards,
        total,
        isLoading,
        limit,
        page
    }
}