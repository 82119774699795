export const tutorialSelector = (state) => {
    const {
        tutorials,
        totalTutorials,
        isLoading,
        limit,
        page,
    } = state.tutorial

    return {
        tutorials,
        totalTutorials,
        isLoading,
        limit,
        page,
    }
}