import React, { useState } from "react";
import AdminSidebar from "../AdminSidebar";
import DashboardHeader from "../DashboardHeader";
import { Col, Dropdown, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function DashboardInvest() {

  const [showContent, setShowContent] = useState(false);

const toggleSidebar = () => setShowContent(!showContent);
  return (
    <>
      <div className={showContent ? 'dashboard-main d-flex content-visible' : 'dashboard-main d-flex'}>
        <div>
          <AdminSidebar />
        </div>
        <div className="dashboard-body">
                    <DashboardHeader toggleSidebar={toggleSidebar} />

          <div className="dashboard-content p-2 p-md-4">
            <Row>
              <Col md={4}></Col>
              <Col md={8} className="mb-4">
                <div className="text-end">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Single Family Homes
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3} className="mb-4">
                <div className="investPropertycard p-3">
                  <img
                    className="property-img img-fluid mb-4"
                    src={require("../../../images/authbanner1.png")}
                    alt=""
                  />
                  <Link to={"/propertydetails"}>
                    <h4 className="propertyHeading">Single Family Homes</h4>
                  </Link>
                  <p className="address mb-2">
                    <span className="me-2">
                      <FontAwesomeIcon icon={faLocationPin} />
                    </span>
                    Terry Lane, Golden CO 80403
                  </p>
                  <p className="expected_rate mb-0">
                    Expected Income &nbsp;
                    <span className="rate">9.88 %</span>
                  </p>
                </div>
              </Col>
              <Col md={3} className="mb-4">
                <div className="investPropertycard p-3">
                  <img
                    className="property-img img-fluid mb-4"
                    src={require("../../../images/authbanner1.png")}
                    alt=""
                  />
                  <Link to={"/propertydetails"}>
                    <h4 className="propertyHeading">Single Family Homes</h4>
                  </Link>
                  <p className="address mb-2">
                    <span className="me-2">
                      <FontAwesomeIcon icon={faLocationPin} />
                    </span>
                    Terry Lane, Golden CO 80403
                  </p>
                  <p className="expected_rate mb-0">
                    Expected Income &nbsp;
                    <span className="rate">9.88 %</span>
                  </p>
                </div>
              </Col>
              <Col md={3} className="mb-4">
                <div className="investPropertycard p-3">
                  <img
                    className="property-img img-fluid mb-4"
                    src={require("../../../images/authbanner1.png")}
                    alt=""
                  />
                  <Link to={"/propertydetails"}>
                    <h4 className="propertyHeading">Single Family Homes</h4>
                  </Link>
                  <p className="address mb-2">
                    <span className="me-2">
                      <FontAwesomeIcon icon={faLocationPin} />
                    </span>
                    Terry Lane, Golden CO 80403
                  </p>
                  <p className="expected_rate mb-0">
                    Expected Income &nbsp;
                    <span className="rate">9.88 %</span>
                  </p>
                </div>
              </Col>
              <Col md={3} className="mb-4">
                <div className="investPropertycard p-3">
                  <img
                    className="property-img img-fluid mb-4"
                    src={require("../../../images/authbanner1.png")}
                    alt=""
                  />
                  <Link to={"/propertydetails"}>
                    <h4 className="propertyHeading">Single Family Homes</h4>
                  </Link>
                  <p className="address mb-2">
                    <span className="me-2">
                      <FontAwesomeIcon icon={faLocationPin} />
                    </span>
                    Terry Lane, Golden CO 80403
                  </p>
                  <p className="expected_rate mb-0">
                    Expected Income &nbsp;
                    <span className="rate">9.88 %</span>
                  </p>
                </div>
              </Col>
              <Col md={3} className="mb-4">
                <div className="investPropertycard p-3">
                  <img
                    className="property-img img-fluid mb-4"
                    src={require("../../../images/authbanner1.png")}
                    alt=""
                  />
                  <Link to={"/propertydetails"}>
                    <h4 className="propertyHeading">Single Family Homes</h4>
                  </Link>
                  <p className="address mb-2">
                    <span className="me-2">
                      <FontAwesomeIcon icon={faLocationPin} />
                    </span>
                    Terry Lane, Golden CO 80403
                  </p>
                  <p className="expected_rate mb-0">
                    Expected Income &nbsp;
                    <span className="rate">9.88 %</span>
                  </p>
                </div>
              </Col>
              <Col md={3} className="mb-4">
                <div className="investPropertycard p-3">
                  <img
                    className="property-img img-fluid mb-4"
                    src={require("../../../images/authbanner1.png")}
                    alt=""
                  />
                  <Link to={"/propertydetails"}>
                    <h4 className="propertyHeading">Single Family Homes</h4>
                  </Link>
                  <p className="address mb-2">
                    <span className="me-2">
                      <FontAwesomeIcon icon={faLocationPin} />
                    </span>
                    Terry Lane, Golden CO 80403
                  </p>
                  <p className="expected_rate mb-0">
                    Expected Income &nbsp;
                    <span className="rate">9.88 %</span>
                  </p>
                </div>
              </Col>
              <Col md={3} className="mb-4">
                <div className="investPropertycard p-3">
                  <img
                    className="property-img img-fluid mb-4"
                    src={require("../../../images/authbanner1.png")}
                    alt=""
                  />
                  <Link to={"/propertydetails"}>
                    <h4 className="propertyHeading">Single Family Homes</h4>
                  </Link>
                  <p className="address mb-2">
                    <span className="me-2">
                      <FontAwesomeIcon icon={faLocationPin} />
                    </span>
                    Terry Lane, Golden CO 80403
                  </p>
                  <p className="expected_rate mb-0">
                    Expected Income &nbsp;
                    <span className="rate">9.88 %</span>
                  </p>
                </div>
              </Col>
              <Col md={3} className="mb-4">
                <div className="investPropertycard p-3">
                  <img
                    className="property-img img-fluid mb-4"
                    src={require("../../../images/authbanner1.png")}
                    alt=""
                  />
                  <Link to={"/propertydetails"}>
                    <h4 className="propertyHeading">Single Family Homes</h4>
                  </Link>
                  <p className="address mb-2">
                    <span className="me-2">
                      <FontAwesomeIcon icon={faLocationPin} />
                    </span>
                    Terry Lane, Golden CO 80403
                  </p>
                  <p className="expected_rate mb-0">
                    Expected Income &nbsp;
                    <span className="rate">9.88 %</span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardInvest;
