export const transactionSelector = (state) => {
    const {
        transactions,
        total,
        isLoading,
        limit,
        page,
        walletBalance,
        payoutTotal,
        payouts
    } = state.transaction

    return {
        transactions,
        total,
        isLoading,
        limit,
        page,
        walletBalance,
        payoutTotal,
        payouts
    }
}