import React, { useEffect, useRef, useCallback } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { registerThunk, socialLoginThunk } from "../../features/auth/authThunk";
import { useNavigate } from "react-router-dom";
import { signupValidationSchema } from "../../utils/validationsSchema";
import { toast } from "react-hot-toast";
import {
  LoginSocialFacebook,
  LoginSocialGoogle,
} from "reactjs-social-login";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleRef = useRef();
  const onLoginStart = useCallback((success, error) => { }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
      full_name: "",
    },
    validationSchema: signupValidationSchema,
    onSubmit: async (values) => {

      try {
        const response = await dispatch(registerThunk(values));

        if (response?.payload?.status === 200) {
          toast.success('Verification email is sent on registered email, Please check your email.')
          navigate("/login");
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  });

  const googleLoginResponse = async ({ provider, data }) => {
    try {
      if (data) {
        let payload = {
          social_id: data?.sub,
          first_name: data?.given_name,
          last_name: data?.family_name,
          full_name: data?.name,
          email: data?.email,
        };

        const res = await dispatch(socialLoginThunk(payload));

        if (res?.payload?.status == 200) {
          navigate("/profileComplete");
          toast.success('Signup successfully');
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const facebookLoginResponse = async ({ provider, data }) => {
    console.log(provider);
    console.log(data)
    try {
      if (data) {
        let payload = {
          social_id: data?.id,
          first_name: data?.first_name,
          last_name: data?.last_name,
          full_name: data?.name,
          email: data?.email,
        };

        const res = await dispatch(socialLoginThunk(payload));

        if (res?.payload?.status == 200) {
          navigate("/profileComplete");
          toast.success('Login successfully');
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const onLogoutSuccess = useCallback(() => {
    alert('logout success');
  }, []);

  return (
    <div>
      <div className="authWrapper">
        <Row className="m-0 align-items-center">
          <Col lg={8} md={6} className="ps-md-0">
            <img
              src={require("../../images/authbanner.png")}
              className="login-side-img d-none d-md-block"
            />
          </Col>
          <Col lg={4} md={6}>
            <div className="logo_div text-center pt-4">
              <img src={require("../../images/logo.png")} alt="" />
            </div>
            <div className="login-form-wrapper">
              <h3 className="text-center">Sign Up</h3>
              <p className="text-center mb-4 welcome-back-content">
                Fill your detail to Create a new Account.
              </p>
              <div className="form-inner-div mx-auto">
                <form onSubmit={formik.handleSubmit}>
                  <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      name="full_name"
                      onChange={formik.handleChange}
                      value={formik.values.full_name}
                      type="text"
                    />
                    <span style={{ color: "red" }}>
                      {formik.errors.full_name ? (
                        <div>{formik.errors.full_name} </div>
                      ) : null}{" "}
                    </span>
                  </Form.Group>

                  <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      name="username"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                      type="text"
                    />
                    <span style={{ color: "red" }}>
                      {formik.errors.username ? (
                        <div>{formik.errors.username} </div>
                      ) : null}{" "}
                    </span>
                  </Form.Group>

                  <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      type="text"
                    />
                    <span style={{ color: "red" }}>
                      {formik.errors.email ? (
                        <div>{formik.errors.email} </div>
                      ) : null}{" "}
                    </span>
                  </Form.Group>

                  <Form.Group as={Col} md="12">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      type="password"
                    />
                    <span style={{ color: "red" }}>
                      {formik.errors.password ? (
                        <div>{formik.errors.password} </div>
                      ) : null}{" "}
                    </span>
                  </Form.Group>

                  <Button type="submit" className="login-btn px-4 w-100">
                    Sign Up
                  </Button>
                </form>
                <p className="or-with-text my-4 text-center position-relative">
                  Or Continue with
                </p>
                <div className="d-flex flex-wrap gap-5 social-imgs justify-content-center">
                  <LoginSocialFacebook
                    appId={process.env.REACT_APP_FB_APP_ID || ''}
                    fieldsProfile={
                      'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                    }
                    onLoginStart={onLoginStart}
                    onLogoutSuccess={onLogoutSuccess}
                    redirect_uri={`${process.env.REDIRECT_URI}`}
                    onResolve={facebookLoginResponse}
                    onReject={err => {
                      console.log(err);
                    }}
                    className="cursor-pointer"
                  >
                    <img src={require("../../images/facebook 1.png")} alt="fb" />
                  </LoginSocialFacebook>
                  <LoginSocialGoogle
                    ref={googleRef}
                    client_id={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                    onLoginStart={onLoginStart}
                    scope="profile https://www.googleapis.com/auth/userinfo.profile email https://www.googleapis.com/auth/userinfo.email"
                    discoveryDocs="claims_supported"
                    onResolve={googleLoginResponse}
                    onReject={(err) => {
                      console.log(err);
                    }}
                    fetch_basic_profile={true}
                    redirect_uri={`${process.env.REDIRECT_URI}`}
                    className="cursor-pointer"
                  >
                    <img
                      src={require("../../images/search 1.png")}
                      alt="google"
                    />
                  </LoginSocialGoogle>
                </div>
                <p className="mt-5 text-center have-account-div">
                  Already have an Account?{" "}
                  <Link to={"/login"} className="text-decoration-none">
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SignUp;
