import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resetPasswordThunk } from "../../features/auth/authThunk";
import { resetPasswordSchema } from "../../utils/validationsSchema";

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const formik = useFormik({
    initialValues: {
      token: token,
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => { 
      try {
        const response = await dispatch(resetPasswordThunk(values));
      
        if (response?.payload?.status === 200) {
            // navigate("/login");
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  });
  return (
    <>
      <div className="authWrapper">
        <Row className="m-0 align-items-center">
          <Col lg={8} md={6} className="ps-md-0">
            <img
              src={require("../../images/authbanner.png")}
              className="login-side-img d-none d-md-block"
            />
          </Col>
          <Col lg={4} md={6}>
            <div className="logo_div text-center pt-4">
              <img src={require("../../images/logo.png")} alt="" />
            </div>
            <div className="login-form-wrapper">
              <h3 className="text-center">Reset Password</h3>
              <p className="text-center mb-4 welcome-back-content">
                Enter a new password below to change your password
              </p>
              <div className="form-inner-div mx-auto">
                <form onSubmit={formik.handleSubmit}>
                  <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      name="newPassword"
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}
                      type="password"
                    />
                    <span style={{ color: "red" }}>
                      {formik.errors.newPassword ? (
                        <div>{formik.errors.newPassword} </div>
                      ) : null}{" "}
                    </span>
                  </Form.Group>
                  <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      name="confirmPassword"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      type="password"
                    />
                    <span style={{ color: "red" }}>
                      {formik.errors.confirmPassword ? (
                        <div>{formik.errors.confirmPassword} </div>
                      ) : null}{" "}
                    </span>
                  </Form.Group>

                  <Button type="submit" className="login-btn px-4 w-100">
                    Reset Password
                  </Button>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ResetPassword;
