
export const blogSelector = (state) => {
    const {
        blogs,
        total,
        isLoading,
        limit,
        page,
        blog
    } = state.blog

    return {
        blogs,
        total,
        isLoading,
        limit,
        page,
        blog
    }
}