import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import {
    getTransactionsThunk,
    getTransactionStatsThunk,
    approvedPayoutThunk
} from "./transactionThunk";


const initialState = {
    transactions: [],
    total: 0,
    isLoading: false,
    limit: 10,
    page: 1,
    walletBalance: 0,

    payouts: [],
    payoutTotal: 0
};

const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        changeLimit: (state, action) => {
            state.limit = action.payload;
        },
        changePage: (state, action) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTransactionsThunk.fulfilled, (state, action) => {
                if (action?.payload?.status === 200) {
                    state.transactions = action?.payload?.data?.body?.data;
                    state.total = action?.payload?.data?.body?.count;
                }
            })

            .addCase(getTransactionStatsThunk.fulfilled, (state, action) => {
                if (action?.payload?.status === 200) {
                    state.walletBalance = action?.payload?.data?.body?.totalBalance;
                    //state.total = action?.payload?.data?.body?.count;
                }
            })

            .addCase(approvedPayoutThunk.fulfilled, (state, action) => {
                if (action?.payload?.status === 200) {
                    state.payouts = action?.payload?.data?.body?.data;
                    state.payoutTotal = action?.payload?.data?.body?.count;
                }
            })
    },
});

export const { changeLimit, changePage } = transactionSlice.actions;
export default transactionSlice.reducer;
