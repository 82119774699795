import React from "react";
import { Link, useLocation } from "react-router-dom";

function AdminNavBar() {
  const location = useLocation();
  return (
    <>
      <div className="top-navlinks">
        <ul className="navbar_list list-unstyled d-flex align-items-center gap-3">
          <li className={`${location.pathname === "/portfolio" ? "active" : ""}`}>
            <Link to={"/portfolio"}>
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.1999 5.75993H14.0351L12.9119 3.51353C12.6725 3.03387 12.3039 2.63057 11.8476 2.34902C11.3914 2.06748 10.8656 1.91887 10.3295 1.91993H4.79992C4.0361 1.91993 3.30356 2.22336 2.76345 2.76346C2.22335 3.30356 1.91992 4.0361 1.91992 4.79993V19.1999C1.91992 19.9638 2.22335 20.6963 2.76345 21.2364C3.30356 21.7765 4.0361 22.0799 4.79992 22.0799H19.1999C19.9637 22.0799 20.6963 21.7765 21.2364 21.2364C21.7765 20.6963 22.0799 19.9638 22.0799 19.1999V8.63993C22.0799 7.8761 21.7765 7.14356 21.2364 6.60346C20.6963 6.06335 19.9637 5.75993 19.1999 5.75993ZM4.79992 3.83993H10.3295C10.5084 3.83868 10.6841 3.88746 10.8368 3.98075C10.9895 4.07405 11.113 4.20815 11.1935 4.36793L11.8847 5.75993H4.79992C4.47242 5.76252 4.14777 5.82096 3.83992 5.93273V4.79993C3.83992 4.54532 3.94106 4.30114 4.1211 4.1211C4.30113 3.94107 4.54531 3.83993 4.79992 3.83993ZM20.1599 19.1999C20.1599 19.4545 20.0588 19.6987 19.8787 19.8787C19.6987 20.0588 19.4545 20.1599 19.1999 20.1599H4.79992C4.54531 20.1599 4.30113 20.0588 4.1211 19.8787C3.94106 19.6987 3.83992 19.4545 3.83992 19.1999V8.63993C3.83992 8.38532 3.94106 8.14114 4.1211 7.9611C4.30113 7.78107 4.54531 7.67993 4.79992 7.67993H19.1999C19.4545 7.67993 19.6987 7.78107 19.8787 7.9611C20.0588 8.14114 20.1599 8.38532 20.1599 8.63993V19.1999Z"
                    fill="#7E7E7E"
                  />
                </svg>
              </span>
              <span className="word">My Portfolio</span>
            </Link>
          </li>
          <li className={`${location.pathname === "/transactions" ? "active" : ""}`}>
            <Link to={"/transactions"}>
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="22"
                  viewBox="0 0 24 22"
                  fill="none"
                >
                  <path
                    d="M2.57124 10L21.4286 10C22.2971 10 23 9.19404 23 8.20018C23 7.20632 22.2971 6.40036 21.4286 6.40036L5.5078 6.40036L7.02173 3.79908C7.5029 2.97206 7.30789 1.85419 6.58596 1.30326C5.86247 0.75054 4.88708 0.974617 4.40684 1.80236L1.26396 7.20182C0.942441 7.75364 0.912584 8.46385 1.1857 9.04916C1.45945 9.63446 1.99185 10 2.57124 10Z"
                    fill="#7E7E7E"
                    stroke="white"
                  />
                  <path
                    d="M2.57145 15.6L18.4931 15.6L16.9784 18.2015C16.4965 19.0286 16.6914 20.1466 17.4141 20.6976C17.6828 20.9024 17.9852 21 18.2844 21C18.7923 21 19.2911 20.7187 19.5934 20.1985L22.7363 14.7985C23.057 14.2466 23.0877 13.5363 22.8146 12.9509C22.5414 12.3656 22.0089 12 21.4289 12L2.57145 12C1.7037 12 1 12.806 1 13.8C1 14.794 1.7037 15.6 2.57145 15.6Z"
                    fill="#7E7E7E"
                    stroke="white"
                  />
                </svg>
              </span>
              <span className="word">Transaction</span>
            </Link>
          </li>
          <li className={`${location.pathname === "/wallet" ? "active" : ""}`}>
            <Link to={"/wallet"}>
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M20 7.42822H4C2.89543 7.42822 2 8.32365 2 9.42822V19.4282C2 20.5328 2.89543 21.4282 4 21.4282H20C21.1046 21.4282 22 20.5328 22 19.4282V9.42822C22 8.32365 21.1046 7.42822 20 7.42822Z"
                    stroke="#878787"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 21.4282V5.42822C16 4.89779 15.7893 4.38908 15.4142 4.01401C15.0391 3.63894 14.5304 3.42822 14 3.42822H10C9.46957 3.42822 8.96086 3.63894 8.58579 4.01401C8.21071 4.38908 8 4.89779 8 5.42822V21.4282"
                    stroke="#878787"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span className="word">Wallet Page</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default AdminNavBar;
