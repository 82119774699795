

export const authSelector = (state) => {
  const {
    userData,
    token,
    otpVerified,
    isLoading
  } = state.auth

  return {
    userData,
    token,
    otpVerified,
    isLoading
  }
}

